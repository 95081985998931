import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useNavigate } from "react-router-dom";
import { Steps } from "antd";
import {  Role } from "../../utils/constant/Constant";
import api from "../../services/Api";
import { useToast } from "../../utils/helpers/CommFun";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllClientBranches,
} from "../../redux/slices/clientBranchSlice";
import {
  createOrUpdateCase,
  fetchAllCases,
} from "../../redux/slices/caseSlice";

const AddNewCaseWithApplicant = () => {
  const dispatch = useDispatch();
  const { clientBranches } = useSelector((state) => state.clientBranch);

  useEffect(() => {
    dispatch(fetchAllClientBranches({}));
  }, [dispatch]);

  const userData = JSON.parse(localStorage.getItem("user_data"));
  const [currentStep, setCurrentStep] = useState(0); // Tracks current step in the form process
  const navigate = useNavigate();

  const [initialValue, setInitialValue] = useState();

  const clear = () => {
    if (currentStep === 0) {
      navigate(-1);
    } else {
      setCurrentStep(0);
    }
  };

  const caseFormConfig = [
    {
      label: "Branch",
      name: "branchId",
      type: "select",
      options: clientBranches.map((item) => ({
        value: item.id,
        label: item.branch_name,
      })),
      colSpan: 12,
      rules: [{ required: true, message: "Please Select One Option" }],
    },
    {
      label: "Received",
      name: "received_at",
      type: "date",
      colSpan: 12,
      rules: [{ required: true, message: "Please Select Date" }],
    },
    {
      label: "Loan Type",
      name: "loan_type",
      type: "select",
      options: [
        { value: 1, label: "Home Loan" },
        { value: 2, label: "Car Loan" },
        { value: 3, label: "Business Loan" },
        { value: 4, label: "Personal Loan" },
      ],
      colSpan: 12,
    },
    {
      label: "Loan Amount",
      name: "loan_amount",
      type: "input",
      colSpan: 12,
    },
  ];

  // Form configuration for the second form
  const applicantFormConfig = [
    {
      label: "Type",
      name: "type",
      type: "select",
      options: [{ value: 1, label: "Applicant" }],
      rules: [{ required: true, message: "Please Select One Option" }],
      colSpan: 12,
    },
    {
      label: "First Name",
      name: "first_name",
      type: "input",
      rules: [{ required: true, message: "Please Enter First Name" }],
      colSpan: 12,
    },
    {
      label: "Middle Name",
      name: "middle_name",
      type: "input",
      colSpan: 12,
    },
    {
      label: "Last Name",
      name: "last_name",
      type: "input",
      rules: [{ required: true, message: "Please Enter Last Name" }],
      colSpan: 12,
    },
    {
      label: "Mobile 1",
      name: "mobile_no",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Mobile 1" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Mobile 2",
      name: "mobile_no_2",
      type: "input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Resident Mobile Number",
      name: "res_no",
      type: "input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Office",
      name: "office_no",
      type: "input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "applicantId",
      name: "applicantId",
      type: "hidden",
      initialValue: null,
    },
  ];
  const redirctToCaseDetails = async () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case");
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case");
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case");
    } else {
      navigate("/login");
    }
  };
  //ADD NEW APPLICANT
  const CreateApplicant = async () => {
    const body = {
      branch_id: parseInt(initialValue.branchId),
      received_at: initialValue.received_at,
      type: initialValue.type,
      name: initialValue.first_name + " " + initialValue.last_name || "",
      first_name: initialValue.first_name || "",
      middle_name: initialValue.middle_name || "",
      last_name: initialValue.last_name || "",
      mobile_no: parseInt(initialValue.mobile_no),
      mobile_no_2: parseInt(initialValue.mobile_no_2),
      res_no: parseInt(initialValue.res_no),
      office_no: parseInt(initialValue.office_no),
      loan_type: initialValue.loan_type || 0,
      loan_amount: initialValue.loan_amount || 0,
      user_id: parseInt(userData.id),
    };
    const isUpdate = false;
    dispatch(createOrUpdateCase({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        dispatch(fetchAllCases({}));
        redirctToCaseDetails();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };

  // Handle form submission
  const handleSubmit = (values) => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else if (currentStep === 1) {
      CreateApplicant();
    }
  };
  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5 className="bottom-line">Add New Case</h5>
                </div>
              </div>
            </div>
          </div>
          <section id="add-new-user">
            <div className="add_user-box">
              <div className="add_user-form">
                <Steps
                  size="large"
                  current={currentStep}
                  items={[{ title: "Case" }, { title: "Applicant" }]}
                  className="mb-3"
                />
                <DynamicForm
                  config={
                    currentStep === 0 ? caseFormConfig : applicantFormConfig
                  }
                  submitText={currentStep === 0 ? "Next" : "Submit"}
                  initialValues={initialValue}
                  handleFieldChange={handleFieldChange}
                  onCancel={clear}
                  onFinish={handleSubmit}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddNewCaseWithApplicant;
