import React, { useState, useEffect } from "react";
import api from "../../services/Api";
import {
  useToast,
  createUrlObj,
  triggerConfirmation,
  validateStr,
} from "../../utils/helpers/CommFun";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL, Role, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  BackOfficeFormConfig,
  BankFormConfig,
  BranchVendorFormConfig,
  FieldExecFormConfig,
  MasterVendorFormConfig,
  TeamLeadFormConfig,
  userTypeOptions,
} from "./AddUserFormConfig";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStateCity,
  resetStateCity,
} from "../../redux/slices/stateCitySlice";
import {
  fetchAllPincodes,
  pincodeStatusReset,
} from "../../redux/slices/pincodeSlice";
import {
  fetchAllVendorBranches,
  fetchUserMasterBranch,
} from "../../redux/slices/vendorBranchSlice";
import { fetchAllClientBranches } from "../../redux/slices/clientBranchSlice";
import {
  checkUserBranches,
  checkUserPincodes,
  createOrUpdateUser,
  fetchAllTeamLead,
  fetchPerticularUser,
  resetSelectedUser,
  resetUserState,
} from "../../redux/slices/userSlice";

function AddNewuserForm() {
  const dispatch = useDispatch();
  const { pincodeId, city, state } = useSelector((state) => state.stateCity);
  const { vendorBranches } = useSelector((state) => state.vendorBranch);
  const { clientBranches } = useSelector((state) => state.clientBranch);
  const { teamLeads, selectedUser, assignedBranches, assignedPincodes } =
    useSelector((state) => state.user);
  const { pincodes } = useSelector((state) => state.pincode);

  //For Form Config
  const [initialValue, setInitialValue] = useState(null);

  //Token and user Data
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));
  //For Displaying message
  const Toast = useToast();
  const navigate = useNavigate();
  //For Update
  const userLocation = useLocation();
  const [userId, setUserId] = useState(userLocation.state?.user_id || null);
  const [selectTL, setSelectTL] = useState("");
  const [baseFormConfig, setBaseFormConfig] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchAllVendorBranches({}));
    dispatch(resetStateCity());
    dispatch(fetchAllPincodes({}));
    dispatch(fetchAllClientBranches({}));

    if (!userId) {
      dispatch(resetSelectedUser());
    }
  }, [dispatch]);

  useEffect(() => {
    setInitialValue((prev) => {
      return {
        ...prev,
        pincodeId,
        city,
        state,
      };
    });
  }, [pincodeId]);
  //Gathers Require Lists
  useEffect(() => {
    getOptions();
    if (
      userData.role == Role.MASTER_VENDOR ||
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD
    ) {
      getMasterBranchList();
    }
  }, []);

  //For Update Fetch User Details, after the required fields are added
  useEffect(() => {
    if (userId) {
      const body = {
        user_id: userId,
      };
      dispatch(fetchPerticularUser(body));
    }
  }, [userId, vendorBranches, clientBranches]);

  useEffect(() => {
    if (userId && selectedUser) {
      const userDetails = {
        userId: selectedUser.id,
        firstName: selectedUser.first_name,
        middleName: selectedUser.middle_name,
        lastName: selectedUser.last_name,
        password: selectedUser.password,
        email: selectedUser.email,
        mobile_01: selectedUser.mobile ? parseInt(selectedUser.mobile) : null,
        gst_no: selectedUser.gst_no,
        pan_no: selectedUser.pan_no,
        gst_per: selectedUser.gst_per,
        selectUserRole: selectedUser.role,
        mobile_02: selectedUser.mobile_2
          ? parseInt(selectedUser.mobile_2)
          : null,
        home_mobile: selectedUser.home_mobile
          ? parseInt(selectedUser.home_mobile)
          : null,
        address: selectedUser.address,
        // designation: selectedUser.designation,
        relation: selectedUser.relation,
        // location: selectedUser.location,
        relation_name: selectedUser.relation_name,
        relation_mobile: selectedUser.relation_mobile
          ? parseInt(selectedUser.relation_mobile)
          : null,
        branch: selectedUser.branch_id ? selectedUser.branch_id?.id : null,
        assign_vendor_branch: selectedUser.branches.vendor_branch
          ? selectedUser.branches?.vendor_branch
          : null,
        pincode: selectedUser?.pincode?.pincode,
        state: selectedUser?.pincode?.state_name,
        city: selectedUser?.pincode?.district,

        assign_client_branches: selectedUser.branches?.branch,
        assign_pincodes: selectedUser.pincodes?.pin,

        identityProof: selectedUser.identity_proof
          ? createUrlObj("identityProof", selectedUser.identity_proof)
          : undefined,
        addressProof: selectedUser.address_proof
          ? createUrlObj("addressProof", selectedUser.address_proof)
          : undefined,
        photoProof: selectedUser.photo_proof
          ? createUrlObj("photoProof", selectedUser.photo_proof)
          : undefined,
        logoPhoto: selectedUser.logo
          ? createUrlObj("logoPhoto", selectedUser.logo)
          : undefined,
        resume: selectedUser.resume
          ? createUrlObj("resume", selectedUser.resume)
          : undefined,
        stamp: selectedUser.stamp
          ? createUrlObj("stamp", selectedUser.stamp)
          : undefined,
        signature: selectedUser.signature
          ? createUrlObj("signature", selectedUser.signature)
          : undefined,
      };

      if (userDetails.selectUserRole)
        userDetails.teamLead = JSON.stringify(selectedUser.parent_id);

      setInitialValue(userDetails);
    }
  }, [selectedUser]);

  //On Branch Select, Get TeamLead List
  useEffect(() => {
    if (validateStr(initialValue?.branch)) getTeamLeadList();
    // getbackofficeList();
  }, [initialValue?.branch]);

  useEffect(() => {
    getFormConfig();
  }, [initialValue?.selectUserRole, initialValue?.branch, options, teamLeads]);

  //Get the Branch list
  const getMasterBranchList = async () => {
    userData.role == Role.TEAM_LEAD
      ? dispatch(fetchUserMasterBranch({}))
      : dispatch(fetchAllVendorBranches({}));
  };

  //GET Team lead list
  const getTeamLeadList = async () => {
    const body = {
      branch_id: initialValue?.branch,
    };
    dispatch(fetchAllTeamLead(body));
  };

  //GET Back Office List
  // const getbackofficeList = async () => {
  //   try {
  //     const body = {
  //       master_id: userData.id,
  //     };
  //     const response = await api.post(API_URL.GET_ALL_BACKOFFICE, body, token);
  //     if (response.status === 200) {
  //       const backofficeList = response.result.map((item) => ({
  //         value: item.id,
  //         label: `${item.first_name} ${
  //           item.middle_name ? item.middle_name : ""
  //         } ${item.last_name ? item.last_name : ""}`.trim(),
  //       }));
  //       setBackofficeList(backofficeList);
  //     } else {
  //       console.error("Failed to fetch team lead list");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onCancelButtonClick = () => {
    navigate(-1);
  };

  const appendFormData = (formData, key, value) => {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  };
  const createUser = async (values) => {
    try {
      let master_id;
      const user_id = JSON.parse(localStorage.getItem("user_data")).id;
      let parent_id = user_id;

      switch (userData.role) {
        case Role.MASTER_VENDOR:
          master_id = user_id;
          break;
        case Role.BRANCH_MANAGER:
          master_id = JSON.parse(localStorage.getItem("user_data")).parent_id;
          break;
        case Role.TEAM_LEAD:
          master_id = JSON.parse(localStorage.getItem("user_data")).master_id;
          break;
      }

      if (
        initialValue?.selectUserRole === Role.BACK_OFFICE ||
        initialValue?.selectUserRole === Role.FE_PE
      ) {
        parent_id = selectTL || user_id;
      }

      const formData = new FormData();

      appendFormData(formData, "user_id", userId);
      appendFormData(formData, "first_name", values.firstName);
      appendFormData(formData, "middle_name", values.middleName);
      appendFormData(formData, "last_name", values.lastName);
      if (!userId) appendFormData(formData, "password", values.password);
      appendFormData(formData, "email", values.email);
      appendFormData(formData, "mobile", values.mobile_01);
      appendFormData(formData, "gst_no", values.gst_no);
      appendFormData(formData, "pan_no", values.pan_no);
      appendFormData(formData, "gst_per", values.gst_per);
      appendFormData(formData, "role", values.selectUserRole);
      appendFormData(formData, "mobile_2", values.mobile_02);
      appendFormData(formData, "home_mobile", values.home_mobile);
      appendFormData(formData, "address", values.address);
      // appendFormData(formData,"designation", values.designation);
      appendFormData(formData, "relation", values.relation);
      // appendFormData(formData, "location", values.location);
      appendFormData(formData, "relation_name", values.relation_name);
      appendFormData(formData, "relation_mobile", values.relation_mobile);
      appendFormData(formData, "pincode_id", values.pincodeId);
      // appendFormData(formData, "state", values.state);
      // appendFormData(formData, "city", values.city);
      appendFormData(formData, "parent_id", parent_id);
      appendFormData(
        formData,
        "assign_vendor_branch_id",
        values.assign_vendor_branch
      );
      appendFormData(formData, "branch_id", values.branch);
      if (master_id) appendFormData(formData, "master_id", master_id);
      const appendFile = async (key, file) => {
        if (file) {
          formData.append(key, file[0].originFileObj);
        }
      };
      appendFile("identity_proof", values.identityProof ?? undefined);
      appendFile("address_proof", values.addressProof ?? undefined);
      appendFile("photo_proof", values.photoProof ?? undefined);
      appendFile("resume", values.resume ?? undefined);
      appendFile("logo", values.logoPhoto ?? undefined);
      appendFile("signature", values.signature ?? undefined);
      appendFile("stamp", values.stamp ?? undefined);

      // Handle selected branches and pincodes
      if (
        values.assign_client_branches &&
        values.assign_client_branches.length > 0
      ) {
        formData.append(
          "assign_branch",
          JSON.stringify({ branch_id: values.assign_client_branches })
        );
      }

      if (values.assign_pincodes && values.assign_pincodes.length > 0) {
        formData.append(
          "assign_pincode",
          JSON.stringify({ pincode_id: values.assign_pincodes })
        );
      }
      let response;

      // if (userId) {
      //   response = await api.postFormData(API_URL.UPDATE_USER, formData, token);
      // } else {
      //   response = await api.postFormData(API_URL.CREATE_USER, formData, token);
      // }
      const isUpdate = userId ? true : false;

      dispatch(createOrUpdateUser({ formData, isUpdate }))
        .unwrap()
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {});
      // if (response.status == 200) {
      //   Toast.fire({
      //     icon: "success",
      //     title: response.message,
      //   });
      // } else {
      //   Toast.fire({
      //     icon: "warning",
      //     title: response.message,
      //   });
      //   return;
      // }
      // if (userData.role == Role.SUPER_ADMIN) {
      //   navigate("/super-admin/user");
      // } else if (
      //   userData.role == Role.BANKS ||
      //   userData.role == Role.MASTER_VENDOR
      // ) {
      //   navigate("/admin/user");
      // } else {
      //   navigate("/user/user");
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const getOptions = () => {
    if (userData.role < Role.MASTER_VENDOR) {
      const finalOption = userTypeOptions.filter(
        (item) => item.value <= Role.MASTER_VENDOR
      );
      setOptions(finalOption);
    } else {
      const finalOption = userTypeOptions.filter(
        (item) => item.value > userData.role
      );
      setOptions(finalOption);
    }
  };

  const userType = {
    label: "User Type",
    name: "selectUserRole",
    type: "select",
    options: options,
    rules: [{ required: true, message: "Please select an option" }],
    colSpan: 6,
    disabled: false,
  };

  const getFormConfig = async () => {
    let baseConfig;

    switch (initialValue?.selectUserRole) {
      case Role.BANKS:
        baseConfig = [...BankFormConfig];
        break;
      case Role.MASTER_VENDOR:
        baseConfig = [...MasterVendorFormConfig];
        break;
      case Role.BRANCH_MANAGER:
        baseConfig = [...BranchVendorFormConfig];
        break;
      case Role.TEAM_LEAD:
        baseConfig = [...TeamLeadFormConfig];
        break;
      case Role.BACK_OFFICE:
        baseConfig = [...BackOfficeFormConfig];
        break;
      case Role.FE_PE:
        baseConfig = [...FieldExecFormConfig];
        break;
      default:
        baseConfig = [];
        break;
    }

    baseConfig = [userType, ...baseConfig];
    baseConfig = await setDynamicDataInFIeld(baseConfig);
    setBaseFormConfig(baseConfig);
  };

  const setDynamicDataInFIeld = async (formField) => {
    let set_type;
    set_type = formField.map((field) => {
      switch (field.name) {
        case "branch":
          return userData.role === Role.TEAM_LEAD
            ? {
                ...field,
                options: vendorBranches.map((branch) => ({
                  value: branch.id,
                  label: branch.name,
                })),
                disabled: true,
              }
            : {
                ...field,
                options: vendorBranches.map((branch) => ({
                  value: branch.id,
                  label: branch.name,
                })),
              };
        case "assign_vendor_branch":
          return {
            ...field,
            options: vendorBranches
              .map((branch) => ({
                value: branch.id,
                label: branch.name,
              }))
              .filter((item) => item.value != initialValue?.branch),
          };
        case "assign_client_branches":
          return {
            ...field,
            options: clientBranches.map((branch) => ({
              value: branch.id,
              label: `${branch.branch_name} (${branch.client_code}) `,
            })),
          };
        case "assign_pincodes":
          return {
            ...field,
            options: pincodes.map((pincode) => ({
              value: pincode.id,
              label: pincode.pincode,
            })),
          };
        case "teamLead":
          return {
            ...field,
            options: teamLeads.map((item) => ({
              value: item.id,
              label: `${item.first_name} ${
                item.middle_name ? item.middle_name : ""
              } ${item.last_name ? item.last_name : ""}`.trim(),
            })),
          };
        default:
          return field;
      }
    });

    if (userId) {
      set_type = set_type.map((field) => {
        switch (field.name) {
          case "password":
            return {};
          case "email":
            return { ...field, disabled: true };
          case "selectUserRole":
            return { ...field, disabled: true };
          default:
            return field;
        }
      });
    }
    if (userData.role == Role.TEAM_LEAD) {
      set_type = set_type.map((field) => {
        switch (field.name) {
          case "branch":
            return { ...field, disabled: true };
          case "teamLead":
            return {};
          default:
            return field;
        }
      });
      setInitialValue((prev) => {
        return { ...prev, branch: userData.branch_id.id };
      });
    }
    return set_type;
  };

  useEffect(() => {
    let hasTriggeredForCurrentPincodes = false;

    const showConfirmationForPincode = async () => {
      if (assignedPincodes?.length > 0 && !hasTriggeredForCurrentPincodes) {
        const element = assignedPincodes[0];
        await triggerConfirmation(
          "Already Assigned",
          `This Pincode ${element.pincode} is already assigned to ${element.user_name}`,
          "warning",
          "Ok",
          false
        );
        hasTriggeredForCurrentPincodes = true;
      }
    };

    showConfirmationForPincode();
  }, [assignedPincodes]);

  useEffect(() => {
    let hasTriggeredForCurrentBranch = false;

    const showConfirmationForBranch = async () => {
      if (assignedBranches?.length > 0 && !hasTriggeredForCurrentBranch) {
        const element = assignedBranches[0];
        await triggerConfirmation(
          "Already Assigned",
          `This branch ${element.branch_name} (${element.branch_code}) is already assigned to ${element.user_name}`,
          "warning",
          "Ok",
          false
        );
        hasTriggeredForCurrentBranch = true;
      }
    };

    showConfirmationForBranch();
  }, [assignedBranches]);
  // Optimized function to handle the changes
  function handleBranchChange(initialValue, changedValues) {
    const initialBranches = initialValue?.assign_client_branches || [];
    const changedBranches = changedValues?.assign_client_branches || [];

    // Check if there are new items or if initialValue is empty
    if (changedBranches.length > initialBranches.length) {
      // Get the latest item added
      const latestItem = changedBranches[changedBranches.length - 1];

      // Check if latestItem is not in the initial branches
      if (
        !initialBranches.includes(latestItem) ||
        initialBranches.length === 0
      ) {
        const body = {
          branch: latestItem,
          userRole: initialValue.selectUserRole,
        };
        if (initialValue?.userId) {
          body.userId = initialValue.userId;
        }
        dispatch(checkUserBranches(body));
      }
    }
  }
  function handlePincodeChange(initialValue, changedValues) {
    const initialPincodes = initialValue?.assign_pincodes || [];
    const changedPincodes = changedValues?.assign_pincodes || [];

    // Check if there are new items or if initialValue is empty
    if (changedPincodes.length > initialPincodes.length) {
      // Get the latest item added
      const latestItem = changedPincodes[changedPincodes.length - 1];

      // Check if latestItem is not in the initial branches
      if (
        !initialPincodes.includes(latestItem) ||
        initialPincodes.length === 0
      ) {
        const body = {
          pincode: latestItem,
          userRole: initialValue.selectUserRole,
        };
        if (initialValue?.userId) {
          body.userId = initialValue.userId;
        }
        dispatch(checkUserPincodes(body));
      }
    }
  }

  const handleFieldChange = (changedValues) => {
    if (changedValues.assign_client_branches)
      handleBranchChange(initialValue, changedValues);
    if (changedValues.assign_pincodes)
      handlePincodeChange(initialValue, changedValues);

    if (changedValues.teamLead) {
      setSelectTL(parseInt(changedValues.teamLead));
    }
    if (changedValues.pincode) {
      if (String(changedValues.pincode).length === 6) {
        dispatch(fetchStateCity(changedValues.pincode));
      } else {
        dispatch(resetStateCity());
        setInitialValue((prev) => {
          return { ...prev, pincodeId: null, city: null, state: null };
        });
      }
    }

    if (changedValues.branch) {
      setInitialValue((prev) => {
        return { ...prev, assign_vendor_branch: null };
      });
    }

    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  {userId ? (
                    <h5 className="bottom-line">Edit user</h5>
                  ) : (
                    <h5 className="bottom-line">Add New User</h5>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                {/* <div class="button-box">
                      <ul class="">
                    <li><a href="#" class="blue-btn">+ Add New</a></li>
                      </ul>
                      </div> */}
              </div>
            </div>
          </div>
          <section id="add-new-user">
            <div className="add_user-box">
              <div className="add_user-form">
                <DynamicForm
                  key={initialValue}
                  config={baseFormConfig}
                  initialValues={initialValue}
                  onFinish={createUser}
                  handleFieldChange={handleFieldChange}
                  onCancel={onCancelButtonClick}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AddNewuserForm;
