const UserService = {
    getAllUsers: async () => {
      // Fetch all users data
    },
    getUserById: async (id) => {
      // Fetch user data by ID
    },
    updateUser: async (id, userData) => {
      // Update user data
    },
  };
  
  export default UserService;
  