import React, { useEffect, useState } from "react";
import {
  ClockCircleOutlined,
  WindowsOutlined,
  BankOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { useToast } from "../../utils/helpers/CommFun";
import { API_URL } from "../../utils/constant/Constant";
import api from "../../services/Api";
import BranchInfo from "./SubPages/BranchInfo";
import BranchBasicForm from "./SubPages/BranchBasicForm";
import BranchAreaForm from "./SubPages/BranchAreaForm";
import BranchHourForm from "./SubPages/BranchHourForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchPerticularVendorBranch } from "../../redux/slices/vendorBranchSlice";

const BranchTabs = () => {
  const dispatch = useDispatch();
  const { selectedVendorBranch } = useSelector((state) => state.vendorBranch);

  const [activeKey, setActiveKey] = useState("1");
  const [formValues, setFormValues] = useState({});

  const Location = useLocation();
  const [branchId, setBranchId] = useState(Location.state?.BranchId || null);

  useEffect(() => {
    if (selectedVendorBranch) {
      const branchObj = {
        branchId: selectedVendorBranch.id,
        branchName: selectedVendorBranch.name,
        address: selectedVendorBranch.address,
        city: selectedVendorBranch.pincode.district,
        branchCode: selectedVendorBranch.branch_code,
        pincodeId: selectedVendorBranch.pincode.id,
        pincode: selectedVendorBranch.pincode.pincode,
        state: selectedVendorBranch.pincode.state_name,
        branchManager: selectedVendorBranch.bm_id,
        mobile: selectedVendorBranch.mobile,
        bm_name: selectedVendorBranch.bm_name,
        working_hours: selectedVendorBranch.working_hours,
        clPincodes: selectedVendorBranch.cl_pincodes,
        oclPincodes: selectedVendorBranch.ocl_pincodes,
      };
      setFormValues((prev) => ({ ...prev, ...branchObj }));
    }
  }, [selectedVendorBranch]);

  useEffect(() => {
    if (branchId) {
      FetchBranchDetails();
    }
  }, [branchId]);

  const FetchBranchDetails = async () => {
    let body = {
      master_branch_id: parseInt(branchId),
      branch_id: parseInt(branchId),
    };
    dispatch(fetchPerticularVendorBranch(body));
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Branch Information</h5>
                </div>
              </div>
            </div>
            <section id="add-new-user">
              <div div className="add_user-box">
                <div className="add_user-form">
                  <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    items={[
                      {
                        key: "1",
                        label: "Info",
                        children: <BranchInfo formValues={formValues} />,
                        icon: <BankOutlined />,
                      },
                      {
                        key: "2",
                        label: "Basic",
                        children: (
                          <BranchBasicForm
                            setActiveKey={setActiveKey}
                            formValues={formValues}
                            FetchBranchDetails={FetchBranchDetails}
                          />
                        ),
                        icon: <AlignLeftOutlined />,
                      },
                      {
                        key: "3",
                        label: "Area",
                        children: (
                          <BranchAreaForm
                            setActiveKey={setActiveKey}
                            formValues={formValues}
                            FetchBranchDetails={FetchBranchDetails}
                          />
                        ),
                        icon: <WindowsOutlined />,
                      },
                      {
                        key: "4",
                        label: "Hours",
                        children: (
                          <BranchHourForm
                            setActiveKey={setActiveKey}
                            formValues={formValues}
                            FetchBranchDetails={FetchBranchDetails}
                          />
                        ),
                        icon: <ClockCircleOutlined />,
                      },
                    ]}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BranchTabs;
