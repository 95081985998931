import React, { useState, useEffect, useRef } from "react";
import AntdTable from "../../components/AntdTable/Table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateCategory,
  fetchAllCategories,
  deleteCategory,
  categoryStatusReset,
} from "../../redux/slices/categorySlice";
import { STATUS_TYPE } from "../../utils/constant/Constant";
import Swal from "sweetalert2";

const CategoryManagement = () => {
  const dispatch = useDispatch();
  const { categories, total } = useSelector((state) => state.category);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const closeRef = useRef();
  const openRef = useRef();

  useEffect(() => {
    fetchCategoryData();
  }, [dispatch, pageNo, pageSize, searchQuery]);
  const fetchCategoryData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllCategories(body));
  };

  // Clear form Category
  const clear = () => {
    setIsUpdate(false);
    setInitialValue(null);
  };
  const closeModal = () => {
    setIsUpdate(false);
    clear();
    closeRef.current.click();
    setInitialValue(null);
  };
  // Create or update Category
  const handleCreateOrUpdateCategory = async (values) => {
    console.log(values);

    const body = {
      name: values.name,
    };
    if (values.categoryId) body.id = values.categoryId;
    const isUpdate = values.categoryId ? true : false;
    dispatch(createOrUpdateCategory({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        fetchCategoryData();
        closeModal();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };
  const openModal = () => {
    openRef.current.click();
  };
  // Open modal for update
  const openModalforUpdate = async (category) => {
    setIsUpdate(true);
    console.log(category);
    setInitialValue({
      name: category.name,
      categoryId: category.id,
    });
    openModal();
  };
  // Delete category
  const handleDeleteCategory = async (category) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { id: category.id };
        dispatch(deleteCategory(body))
          .unwrap()
          .then(() => {
            fetchCategoryData();
          })
          .catch((err) => { });
      }
    });
  };
  const columns = [
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    {
      dataIndex: "name",
      title: "Name",
      className: "dt-center",
      sortable: true,
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => openModalforUpdate(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteCategory(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const formConfig = [
    {
      label: "Name",
      name: "name",
      type: "input",
      rules: [{ required: true, message: "Please Enter Name" }],
      colSpan: 24,
    },

    {
      label: "categoryId",
      name: "categoryId",
      type: "hidden",
      initialValue: null,
    },
  ];
  return (
    <div>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    <h5>Category Management</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="button-box">
                    <ul className="">
                      <li>
                        <Link
                          to="/"
                          className="blue-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#addEditCategoryModal"
                          ref={openRef}
                        >
                          + Add New
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <section id="category-management">
              <AntdTable
                dataSource={categories}
                columns={columns}
                pageNo={pageNo}
                pageSize={pageSize}
                setPageNo={setPageNo}
                setPageSize={setPageSize}
                total={total}
              />
              <div
                className="modal fade"
                id="addEditCategoryModal"
                tabIndex={-1}
                aria-labelledby="addEditCategoryModal"
                data-bs-backdrop="static"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {isUpdate ? "Update Category" : "Add New Category"}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ref={closeRef}
                        onClick={clear}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <DynamicForm
                        config={formConfig}
                        onFinish={handleCreateOrUpdateCategory}
                        initialValues={initialValue}
                        onCancel={closeModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryManagement;
