import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from "../../services/Api";

import { jsonParser, useToast } from '../../utils/helpers/CommFun';
import { API_URL } from '../../utils/constant/Constant';

const AddPointForm = () => {
    //sweet alert
    const Toast = useToast();
    const location = useLocation();
    const data = location.state;

    const token = localStorage.getItem("token");
    const [template, setTemplate] = useState(null);
    const [formValues, setFormValues] = useState({});
    const navigate = useNavigate();

    const onCancelButtonClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        if (data && data.getData && data.getData.template) {
            const temp = jsonParser(data.getData.template);
            setTemplate(temp);
        }
    }, [data]);
    useEffect(() => {
        getCaseDetails();
    }, [template])

    const handleInputChange = (fieldName, value) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };
    const handleFileInputChange = (fieldName, e) => {
        const file = e.target.files[0];
        if (file) {
            setFormValues({
                ...formValues,
                [fieldName]: file
            });
        }
    };
    const getCaseDetails = async () => {
        try {
            const body = {
                case_id: JSON.parse(data.case_id),
                applicant_id: JSON.parse(data.applicant_id),
                type: JSON.parse(data.type),
            };
            const response = await api.post(API_URL.GET_CASE_DETAILS, body, token);
            if (response.status === 200) {
                const existingCase = response.result.existingCase;
                let tempFormValues = { ...formValues };
                if (template)
                    template.forEach((temp) => {
                        if (Object.keys(existingCase).includes(temp.fieldName)) {
                            tempFormValues[temp.fieldName] = existingCase[temp.fieldName];
                        }
                    }
                    );
                setFormValues(tempFormValues);
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            Object.keys(formValues).forEach((key) => {
                formData.append(key, formValues[key]);
            });
            formData.append('case_id', JSON.parse(data.case_id));
            formData.append('applicant_id', JSON.parse(data.applicant_id));
            formData.append('type', parseInt(data.type));
            formData.append('verification_type', parseInt(data.type));
            formData.append('point_id', parseInt(data.pointData.id));

            const response = await api.post(API_URL.UPDATE_POINTS, formData, token);
            if (response.status === 200) {
                Toast.fire({
                    icon: "success",
                    title: response.message,
                });
                onCancelButtonClick();
            } else {
                Toast.fire({
                    icon: "error",
                    title: response.message,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const renderForm = () => {
        if (!template) {
            return <div>Loading...</div>;
        }

        return (
            <form>
                <div className="row">
                    {template.map((field, index) => (
                        <div key={index} className="col-md-4">
                            <div className="form-box">
                                <label htmlFor={field.fieldName}>{field.fieldDisplayName}</label>
                                {renderField(field)}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="cta-button-box">
                            <button type="button"
                                className="btns submit-btn gray-btn"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={onCancelButtonClick}
                            >Cancel
                            </button>
                            <button type="button"
                                className="btns submit-btn"
                                onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        );
    };

    const renderField = (field) => {
        switch (field.fieldType) {
            case 'Text':
                return (
                    <input
                        className="form-control"
                        type="text"
                        id={field.fieldName}
                        name={field.fieldName}
                        value={formValues[field.fieldName] || ''}
                        onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
                    />
                );
            case 'Number':
                return (
                    <input
                        className="form-control"
                        type="number"
                        id={field.fieldName}
                        name={field.fieldName}
                        value={formValues[field.fieldName] || ''}
                        onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
                    />
                );
            case 'Dropdown':
                return (
                    <select
                        className="form-select form-control"
                        id={field.fieldName}
                        name={field.fieldName}
                        value={formValues[field.fieldName] || ''}
                        onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
                    >
                        <option value="">Select...</option>
                        {field.options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
            case 'Checkbox':
                return (
                    <div>
                        {field.options.map((option, index) => (
                            <div key={index}>
                                <input
                                    className=""
                                    style={{ margin: "5px" }}
                                    type="checkbox"
                                    id={`${field.fieldName}-${index}`}
                                    name={field.fieldName}
                                    value={option}
                                    checked={formValues[field.fieldName]?.includes(option) || false}
                                    onChange={(e) => handleCheckboxChange(field.fieldName, option, e.target.checked)}
                                />
                                <label htmlFor={`${field.fieldName}-${index}`}>{option}</label>
                            </div>
                        ))}
                    </div>
                );
            case 'Date':
                return (
                    <input
                        className="form-control"
                        type="datetime-local"
                        id={field.fieldName}
                        name={field.fieldName}
                        value={formValues[field.fieldName] || ''}
                        onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
                    />
                );
            case 'File':
                return (
                    <input
                        className="form-control"
                        type="file"
                        id={field.fieldName}
                        name={field.fieldName}
                        onChange={(e) => handleFileInputChange(field.fieldName, e)}
                    />
                );
            default:
                return null;
        }
    };

    const handleCheckboxChange = (fieldName, option, checked) => {
        const currentOptions = formValues[fieldName] || [];
        let updatedOptions;

        if (checked) {
            updatedOptions = [...currentOptions, option];
        } else {
            updatedOptions = currentOptions.filter((item) => item !== option);
        }

        setFormValues({
            ...formValues,
            [fieldName]: updatedOptions
        });
    };

    return (
        <div className="main-contant">
            <div className="container-fluid">
                <div className="contant-box">
                    <div className="sub-header">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="title-box">
                                    <h5 className="bottom-line">Add Point Details</h5>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <div class="button-box">
                      <ul class="">
                        <li><a href="#" class="blue-btn">+ Add New</a></li>
                      </ul>
                    </div> */}
                            </div>
                        </div>
                    </div>
                    <section id="add-new-user">
                        <div className="add_user-box">
                            <div className="add_user-form">
                                {renderForm()}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default AddPointForm;
