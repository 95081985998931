// features/stateCity/stateCitySlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constant/Constant";
import api from "../../services/Api";

// Define an async thunk to fetch state and city data based on pincode
export const fetchStateCity = createAsyncThunk(
  "stateCity/fetchStateCity",
  async (pincode, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.post(API_URL.GET_STATES, { pincode }, token);
      if (response.status === 200) {
        return response.result;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const stateCitySlice = createSlice({
  name: "stateCity",
  initialState: {
    pincodeId: null,
    city: null,
    state: null,
    status: "idle",
    error: null,
  },
  reducers: {
    resetStateCity: (state) => {
      state.pincodeId = null;
      state.city = null;
      state.state = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStateCity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStateCity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pincodeId = action.payload?.id;
        state.city = action.payload?.district;
        state.state = action.payload?.state_name;
      })
      .addCase(fetchStateCity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStateCity } = stateCitySlice.actions;
export default stateCitySlice.reducer;
