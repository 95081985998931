// import React, { useState } from "react";
// import { DownOutlined, UpOutlined } from "@ant-design/icons";
// import { Badge, Dropdown, Space, Table } from "antd";

// const NestedTableDemo = () => {
//   const expandedRowRender2 = () => {
//     // const columns = [
//     //   {
//     //     dataIndex: "date",
//     //     key: "date",
//     //   },
//     //   {
//     //     dataIndex: "name",
//     //     key: "name",
//     //   },
//     //   {
//     //     key: "state",
//     //     render: () => <Badge status="success" text="Finished" />,
//     //   },
//     //   {
//     //     dataIndex: "upgradeNum",
//     //     key: "upgradeNum",
//     //   },
//     //   {
//     //     key: "operation",
//     //     render: () => (
//     //       <Space size="middle">
//     //         <a>Pause</a>
//     //         <a>Stop</a>
//     //         <Dropdown
//     //           menu={{
//     //             items,
//     //           }}
//     //         >
//     //           <a>
//     //             More <DownOutlined />
//     //           </a>
//     //         </Dropdown>
//     //       </Space>
//     //     ),
//     //   },
//     // ];
//     // const data = [];
//     // for (let i = 0; i < 3; ++i) {
//     //   data.push({
//     //     key: i.toString(),
//     //     date: "2014-12-24 23:12:00",
//     //     name: "This is production name",
//     //     upgradeNum: "Upgraded: 56",
//     //   });
//     // }
//     return <Table columns={columns} dataSource={data} pagination={false} />;
//   };

//   const expandedRowRender = (record) => {
//     // const columns = [
//     //   {
//     //     dataIndex: "date",
//     //     key: "date",
//     //   },
//     //   {
//     //     dataIndex: "name",
//     //     key: "name",
//     //   },
//     //   {
//     //     key: "state",
//     //     render: () => <Badge status="success" text="Finished" />,
//     //   },
//     //   {
//     //     dataIndex: "upgradeNum",
//     //     key: "upgradeNum",
//     //   },
//     //   {
//     //     key: "operation",
//     //     render: () => (
//     //       <Space size="middle">
//     //         <a>Pause</a>
//     //         <a>Stop</a>
//     //         <Dropdown
//     //           menu={{
//     //             items,
//     //           }}
//     //         >
//     //           <a>
//     //             More <DownOutlined />
//     //           </a>
//     //         </Dropdown>
//     //       </Space>
//     //     ),
//     //   },
//     // ];
//     // const data = [];
//     // for (let i = 0; i < 3; ++i) {
//     //   data.push({
//     //     key: i.toString(),
//     //     date: "2014-12-24 23:12:00",
//     //     name: "This is production name",
//     //     upgradeNum: "Upgraded: 56",
//     //   });
//     // }
//     return (
//       <Table
//         columns={columns}
//         dataSource={data}
//         pagination={false}
//         expandable={{
//           expandedRowRender: expandedRowRender2,
//         //   expandIcon: (props) => (
//         //     <span onClick={props.onExpand} style={{ cursor: "pointer" }}>
//         //       {props.expanded ? <UpOutlined /> : <DownOutlined />}
//         //     </span>
//         //   ),
//         }}
//       />
//     );
//   };

//   const columns = [
//     {

//       dataIndex: "name",
//       key: "name",
//     },
//     {

//       dataIndex: "platform",
//       key: "platform",
//     },
//     {

//       dataIndex: "version",
//       key: "version",
//     },
//     {

//       dataIndex: "upgradeNum",
//       key: "upgradeNum",
//     },
//     {

//       dataIndex: "creator",
//       key: "creator",
//     },
//     {

//       dataIndex: "createdAt",
//       key: "createdAt",
//     },
//     {

//       key: "operation",
//       render: () => <a>Publish</a>,
//     },
//   ];
//   const data = [];
//   for (let i = 0; i < 3; ++i) {
//     data.push({
//       key: i.toString(),
//       name: "Screen",
//       platform: "iOS",
//       version: "10.3.4.5654",
//       upgradeNum: 500,
//       creator: "Jack",
//       createdAt: "2014-12-24 23:12:00",
//     });
//   }
//   return (
//     <>
//       <Table
//         columns={columns}
//         expandable={{
//           expandedRowRender: expandedRowRender,
//           defaultExpandedRowKeys: ['0'],
//         //   expandIcon: (props) => (
//         //     <span onClick={props.onExpand} style={{ cursor: "pointer" }}>
//         //       {props.expanded ? <UpOutlined /> : <DownOutlined />}
//         //     </span>
//         //   ),
//         }}

//         dataSource={data}
//       />
//     </>
//   );
// };

// export default NestedTableDemo;

// import React, { useContext, useEffect, useRef, useState } from 'react';
// import { Button, Form, Input, Popconfirm, Table } from 'antd';
// const EditableContext = React.createContext(null);
// const EditableRow = ({ index, ...props }) => {
//   const [form] = Form.useForm();
//   return (
//     <Form form={form} component={false}>
//       <EditableContext.Provider value={form}>
//         <tr {...props} />
//       </EditableContext.Provider>
//     </Form>
//   );
// };
// const EditableCell = ({
//   title,
//   editable,
//   children,
//   dataIndex,
//   record,
//   handleSave,
//   ...restProps
// }) => {
//   const [editing, setEditing] = useState(false);
//   const inputRef = useRef(null);
//   const form = useContext(EditableContext);
//   useEffect(() => {
//     if (editing) {
//       inputRef.current?.focus();
//     }
//   }, [editing]);
//   const toggleEdit = () => {
//     setEditing(!editing);
//     form.setFieldsValue({
//       [dataIndex]: record[dataIndex],
//     });
//   };
//   const save = async () => {
//     try {
//       const values = await form.validateFields();
//       toggleEdit();
//       handleSave({
//         ...record,
//         ...values,
//       });
//     } catch (errInfo) {
//       console.log('Save failed:', errInfo);
//     }
//   };
//   let childNode = children;
//   if (editable) {
//     childNode = editing ? (
//       <Form.Item
//         style={{
//           margin: 0,
//         }}
//         name={dataIndex}
//         rules={[
//           {
//             required: true,
//             message: `${title} is required.`,
//           },
//         ]}
//       >
//         <Input ref={inputRef} onPressEnter={save} onBlur={save} />
//       </Form.Item>
//     ) : (
//       <div
//         className="editable-cell-value-wrap"
//         style={{
//           paddingInlineEnd: 24,
//         }}
//         onClick={toggleEdit}
//       >
//         {children}
//       </div>
//     );
//   }
//   return <td {...restProps}>{childNode}</td>;
// };
// const NestedTableDemo = () => {
//   const [dataSource, setDataSource] = useState([
//     {
//       key: '0',
//       name: 'Edward King 0',
//       age: '32',
//       address: 'London, Park Lane no. 0',
//     },
//     {
//       key: '1',
//       name: 'Edward King 1',
//       age: '32',
//       address: 'London, Park Lane no. 1',
//     },
//   ]);
//   const [count, setCount] = useState(2);
//   const handleDelete = (key) => {
//     const newData = dataSource.filter((item) => item.key !== key);
//     setDataSource(newData);
//   };
//   const defaultColumns = [
//     {
//       title: 'name',
//       dataIndex: 'name',
//       width: '10%',
//       editable: true,
//     },
//     {
//         title: 'age',
//         dataIndex: 'age',
//         editable: true,
//     },
//     {
//       title: 'address',
//       dataIndex: 'address',
//     },
//     {
//       title: 'operation',
//       dataIndex: 'operation',
//       render: (_, record) =>
//         dataSource.length >= 1 ? (
//           <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
//             <a>Delete</a>
//           </Popconfirm>
//         ) : null,
//     },
//   ];
//   const handleAdd = () => {
//     const newData = {
//       key: count,
//       name: `Edward King ${count}`,
//       age: '32',
//       address: `London, Park Lane no. ${count}`,
//     };
//     setDataSource([...dataSource, newData]);
//     setCount(count + 1);
//   };
//   const handleSave = (row) => {
//     const newData = [...dataSource];
//     const index = newData.findIndex((item) => row.key === item.key);
//     const item = newData[index];
//     newData.splice(index, 1, {
//       ...item,
//       ...row,
//     });
//     setDataSource(newData);
//   };
//   const components = {
//     body: {
//       row: EditableRow,
//       cell: EditableCell,
//     },
//   };
//   const columns = defaultColumns.map((col) => {
//     if (!col.editable) {
//       return col;
//     }
//     return {
//       ...col,
//       onCell: (record) => ({
//         record,
//         editable: col.editable,
//         dataIndex: col.dataIndex,
//         title: col.title,
//         handleSave,
//       }),
//     };
//   });
//   return (
//     <div>
//       <Button
//         onClick={handleAdd}
//         type="primary"
//         style={{
//           marginBottom: 16,
//         }}
//       >
//         Add a row
//       </Button>
//       <Table
//         components={components}
//         rowClassName={() => 'editable-row'}
//         showHeader={false}
//         bordered
//         dataSource={dataSource}
//         columns={columns}
//       />
//     </div>
//   );
// };
// export default NestedTableDemo;

// import React, { useEffect, useState } from 'react';
// import { PlusOutlined } from '@ant-design/icons';
// import { Image, Upload } from 'antd';
// const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// const NestedTableDemo = () => {
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [previewImage, setPreviewImage] = useState('');
//   const [fileList, setFileList] = useState([
//     {
//       uid: '-1',
//       name: 'image.png',
//       status: 'done',
//       url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     },
//     {
//       uid: '-2',
//       name: 'image.png',
//       status: 'done',
//       url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     },
//     {
//       uid: '-3',
//       name: 'image.png',
//       status: 'done',
//       url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     },
//     {
//       uid: '-4',
//       name: 'image.png',
//       status: 'done',
//       url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//     },
//   ]);
//   const handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj);
//     }
//     setPreviewImage(file.url || file.preview);
//     setPreviewOpen(true);
//   };
//   const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
//   const uploadButton = (
//     <button
//       style={{
//         border: 0,
//         background: 'none',
//       }}
//       type="button"
//     >
//       <PlusOutlined />
//       <div
//         style={{
//           marginTop: 8,
//         }}
//       >
//         Upload
//       </div>
//     </button>
//   );
//   return (
//     <>
//       <Upload
//         listType="picture-card"
//         fileList={fileList}
//         onPreview={handlePreview}
//         onChange={handleChange}
//       >
//         {fileList.length >= 8 ? null : uploadButton}
//       </Upload>
//       {previewImage && (
//         <Image
//           wrapperStyle={{
//             display: 'none',
//           }}
//           preview={{
//             visible: previewOpen,
//             onVisibleChange: (visible) => setPreviewOpen(visible),
//             afterOpenChange: (visible) => !visible && setPreviewImage(''),
//           }}
//           src={previewImage}
//         />
//       )}
//     </>
//   );
// };
// export default NestedTableDemo;
import React, { useState } from "react";
import { Form, DatePicker, Button } from "antd";
import moment from "moment";

const NestedTableDemo = () => {
  const [date, setDate] = useState(moment("2024-08-29"));

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  return (
    <Form onFinish={onFinish}>
      <Form.Item name="date" label="Select Date">
        <DatePicker value={date} onChange={(date) => setDate(date)} format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NestedTableDemo;
