import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/Api';
import { useToast } from '../../utils/helpers/CommFun';
import Swal from 'sweetalert2';
import { API_URL, Role } from '../../utils/constant/Constant';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Switch } from "antd";
import AntdTable from "../../components/AntdTable/Table";

function DocumentConfiguration() {
    const [documentList, setDocumentList] = useState([]);

    const Toast = useToast();
    const [clientID, setClientID] = useState("");
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user_data'));

    const clear = () => {
        setClientID("");
        setName("");
        setCode("");
    }

    //Get the client list
    const getDocumentList = async () => {
        try {
            setDocumentList([]);
            const body = {
                user_id: userData.id,
            };
            const response = await api.post(API_URL.GET_CLIENT_DOC, body, token);
            if (response.status === 200) {
                setDocumentList(response.result);
            } else {
                console.error('Failed to fetch client list');
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }

    //Update Status
    const updateDocStatus = async (document) => {
        try {
            document.status = document.status == 1 ? 0 : 1
            const body = {
                doc_id: document.id,
                status: document.status,
            }
            const response = await api.post(API_URL.UPDATE_DOC_STATUS, body, token);
            if (response.status === 200) {
                if (response.status === 200) {
                    Toast.fire({
                        icon: "success",
                        title: response.message,
                    });
                    setDocumentList(prevList =>
                        prevList.map(item =>
                            item.id === document.id ? { ...item, status: document.status } : item
                        )
                    );
                } else {
                    Toast.fire({
                        icon: "error",
                        title: response.message,
                    });
                }
            } else {
                console.error('Failed to fetch document list');
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }
    const onFormSubmit = async () => {
        try {
            if (name == "") {
                Toast.fire({ icon: "error", title: "Name required", });
                return;
            }
            if (code == "") {
                Toast.fire({ icon: "error", title: "Code required", });
                return;
            }
            const body = {
                name: name,
                short_code: code,
            }
            const response = await api.post(`${clientID ? API_URL.UPDATE_CLIENT + clientID : '/client/create_client'}`, body);
            if (response.status === 200) {
                Toast.fire({
                    icon: "success",
                    title: response.message,
                });
                clear();
            } else {
                Toast.fire({
                    icon: "error",
                    title: response.message,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            getDocumentList();
        }
    }

    //Delete Document
    const deleteDocument = async (document) => {
        Swal.fire({
            title: "Delete",
            text: `Are you sure you want to delete document config?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const body = {
                        doc_id: document.id,
                    }
                    const response = await api.post(API_URL.DELETE_DOC, body, token);
                    if (response.status === 200) {
                        Toast.fire({
                            icon: "success",
                            title: response.message,
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: response.message,
                        });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    getDocumentList();
                }
            }
        });
    }


    const columns = [
        { title: "Sr No.", key: "index", render: (_, __, index) => index + 1, className: "dt-center", },
        { dataIndex: "client_name", title: "Client Name", className: 'dt-left',  sortable: true },
        { dataIndex: "verification_type", title: "Verification Type", className: 'dt-center',  sortable: true },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status, record) => (
                <Switch
                    checked={status == 1}
                    onChange={() => updateDocStatus(record)} // Function to handle status change
                />
            ),
            className: "dt-center",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <EditOutlined
                        style={{ color: '#000000', marginRight: 10, cursor: 'pointer' }}
                        onClick={() => onredirect(record)} // Function to handle edit
                    />
                    <DeleteOutlined
                        style={{ color: '#D92E38', cursor: 'pointer' }}
                        onClick={() => deleteDocument(record)} // Function to handle delete
                    />
                </div>
            ),
            className: "dt-center",
        },
        //   {
        //     title: 'Status',
        //     data: "status", className: 'dt-center',
        //     render: (data, type, row) => {
        //         return `   
        //         <td>
        //             <div class="form-check form-switch d-flex justify-content-center">
        //                 <input
        //                     class="form-check-input update-status "
        //                     type="checkbox"
        //                     role="switch"
        //                     ${data == 1 ? "checked" : ""}
        //                 />
        //             </div>
        //         </td>`;
        //     },
        // },
        // {
        //     title: 'Action',
        //     data: null,
        //     className: 'dt-center',
        //     render: (data, type, row) => {
        //         return `
        //                   <ul style="list-style: none; padding: 0; margin: 0; display: flex;" class="justify-content-center">
        //                     <li style="margin-right: 10px; cursor: pointer;" class="redirect-btn">
        //                       <i class="fa-solid fa-pencil" style="color: #000000;"></i>
        //                     </li>
        //                     <li style="cursor: pointer;" class="delete-btn">
        //                       <i class="fa-solid fa-trash-can" style="color: #D92E38;"></i>
        //                     </li>
        //                   </ul>`;
        //     },
        // }
    ];

    const onredirect = async (doc) => {
        const data = {
            doc_id: doc.id,
        };
        if (userData.role == Role.SUPER_ADMIN) {
            navigate("/super-admin/documentconfiguration/addnewdocumentconfig/", { state: data });
        } else if (userData.role == Role.BANKS || userData.role == Role.MASTER_VENDOR) {
            navigate("/admin/documentconfiguration/addnewdocumentconfig/", { state: data });
        } else if (userData.role == Role.BRANCH_MANAGER || userData.role == Role.TEAM_LEAD || userData.role == Role.BACK_OFFICE) {
            navigate("/user/documentconfiguration/addnewdocumentconfig/", { state: data });
        } else {
            navigate('/login')
        }
    }


    useEffect(() => {
        getDocumentList();
    }, []);

    return (
        <>

            <div className="main-contant">
                <div className="container-fluid">
                    <div className="contant-box">
                        <div className="sub-header">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="title-box">
                                        <h5>Document Configuration</h5>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="button-box">
                                        <ul className="">
                                            <li>
                                                <Link
                                                    to={
                                                        [Role.SUPER_ADMIN].some((role) => userData.role == role)
                                                            ? "/super-admin/documentconfiguration/addnewdocumentconfig"
                                                            : [Role.BANKS, Role.MASTER_VENDOR].some((role) => userData.role == role)
                                                                ? "/admin/documentconfiguration/addnewdocumentconfig"
                                                                : "/user/documentconfiguration/addnewdocumentconfig"
                                                    }
                                                    className="blue-btn"
                                                    onClick={clear}>
                                                    + Add New
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AntdTable dataSource={documentList} columns={columns} />
                        {/* <Table data={documentList} columns={columns} onredirect={onredirect} ondelete={deleteDocument} onupdatestatus={updateDocStatus} /> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default DocumentConfiguration;