
import React, { useState, useEffect, useContext } from 'react';
import logo from "../../assets/images/logo.png";
import { Form, Input, Checkbox, Button, Alert } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate, } from 'react-router-dom';
import api from '../../services/Api';
import { useToast } from '../../utils/helpers/CommFun';
import { useLocation } from 'react-router-dom'
import loginBg from "../../assets/images/login-bg.png";
function Password() {
  const [form] = Form.useForm();
  const Toast = useToast();
  // const [currentPassword, setCurrentPassword] = useState('');
  // const [newPassword, setNewPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [passwordVisible3, setPasswordVisible3] = useState(false);
  const location = useLocation();
  const [userId, setUserId] = useState(location.state?.user_id || null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [])
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  const togglePasswordVisibility3 = () => {
    setPasswordVisible3(!passwordVisible3);
  };


  const handleSave = async (values) => {
    const {  currentPassword, newPassword,confirmPassword } = values;
    if (!currentPassword || !newPassword || !confirmPassword) {
      Toast.fire({
        icon: "error",
        title: "All fields are required",
      });
      return;
    }

    if (currentPassword === newPassword) {
      Toast.fire({
        icon: "error",
        title: "New password must be different from current password",
      });
      return;
    }

    if (newPassword.trim() === '') {
      Toast.fire({
        icon: "error",
        title: "Please enter a valid new password",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      Toast.fire({
        icon: "error",
        title: "New password and confirm password must match",
      });
      return;
    }

    try {
      const body = {
        user_id: userId,
        current_password: currentPassword,
        new_password: newPassword,
      };

      const response = await api.post('/user/change_password', body);

      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          title: "Password change Sucessfully",
        });
        navigate('/login');
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message || "Failed to change password",
        });
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "An error occurred while changing password",
      });
      console.error("Error changing password:", error);
    }
  };

  return (
    <section id="login" class="com-form">
      <div className="login-part">
        <div className="container-fluid">
          <div className="login-box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div class="left">
                  <div class="img-box">
                    <img src={loginBg} alt="Login Background" />
                    <div className="text-box">
                      <p>Nice to see you again</p>
                      <h3>welcome back</h3>
                      <div className="line"></div>
                      <h4>Login Now</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-box">
                  <div className="login-box">
                    <div div className="form-box">
                      <div className="logo-box">
                      <img src={logo} alt="logo" />
                      </div>
                      <Form
                        form={form}
                        onFinish={handleSave}
                        layout="vertical"
                      >
                        <Form.Item
                          label=" Current Password"
                          name="currentPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Current password!",
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter current  password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item>
                        <Form.Item
                          label=" New Password"
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter New Password!",
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter New password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item><Form.Item
                          label=" Confirm Password"
                          name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Confirm  password!",
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter Confirm  password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item>
                        <div className="Remember-box"></div>
                        <Form.Item>
                          <Button htmlType="submit" className="btns submit-btn">
                            save
                          </Button>
                        </Form.Item>
                      </Form>
                     </div>
                     </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Password;
{/* <div className="input-box">
                        <label
                          htmlFor="current_password"
                          className="form-label"
                        >
                          Current Password
                        </label>
                        <div className="pass">
                          <input
                            type={passwordVisible1 ? "text" : "password"}
                            id="username"
                            className="form-control"
                            maxLength={30}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            autoComplete="off"

                          />
                          <i
                            className={toggle-password fas ${passwordVisible1 ? "fa-eye" : "fa-eye-slash"
                              }}
                            onClick={togglePasswordVisibility1}
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <label htmlFor="new_password" className="form-label">
                          New Password
                        </label>
                        <div className="pass">
                          <input
                            type={passwordVisible2 ? 'text' : 'password'}
                            id="password"
                            className="form-control"
                            maxLength={50}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <i
                            className={toggle-password fas ${passwordVisible2 ? "fa-eye" : "fa-eye-slash"
                              }}
                            onClick={togglePasswordVisibility2}
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <label htmlFor="confirm_password" className="form-label">
                          Confirm Password
                        </label>
                        <div className="pass">
                          <input
                            type={passwordVisible3 ? 'text' : 'password'}
                            id="confirmPassword"
                            className="form-control"
                            maxLength={50}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}

                          />
                          <i
                            className={toggle-password fas ${passwordVisible3 ? "fa-eye" : "fa-eye-slash"
                              }}
                            onClick={togglePasswordVisibility3}
                          />
                        </div>
                      </div>
                      <div className="button-box">
                        <button
                          type="button"
                          className="btns submit-btn"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div> */}