import React, { useState, useEffect } from "react";
import api from "../../services/Api";
import Swal from "sweetalert2";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../utils/helpers/CommFun";
import { API_URL, Role, STATUS_TYPE } from "../../utils/constant/Constant";
import AntdTable from "../../components/AntdTable/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePickedCase,
  fetchAllPickedCases,
  pickedCaseStatusReset,
} from "../../redux/slices/pickedCaseSlice";

function PickedUpCases() {
  const dispatch = useDispatch();
  const { pickedCases, status, error, total } = useSelector(
    (state) => state.pickedCase
  );
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(pickedCaseStatusReset());
  }, [dispatch]);

  useEffect(() => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllPickedCases(body));
  }, [dispatch,  pageNo, pageSize, searchQuery]);


  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user_data"));

  const getRoute = () => {
    if (userData.role === Role.SUPER_ADMIN) return "/super-admin/updatecase";
    if (userData.role === Role.BANKS || userData.role === Role.MASTER_VENDOR)
      return "/admin/updatecase";
    return "/user/updatecase";
  };

  const handleShow = () => {
    navigate(getRoute());
  };

  const handleEdit = (data) => {
    const body = {
      picked_id: data.id,
    };
    if (userData.role == Role.TEAM_LEAD || userData.role == Role.BACK_OFFICE) {
      navigate("/user/updatecase", {
        state: body,
      });
    } else {
      navigate("/login");
    }
  };

  const handleDeleteRawCase = async (rawcase) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete rawcase?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = {
          raw_case_id: rawcase.id,
        };
        dispatch(deletePickedCase(body))
          .unwrap()
          .then(() => {
            dispatch(fetchAllPickedCases({}));
          })
          .catch((err) => {});
      }
    });
  };

  const columns = [
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    {
      dataIndex: "first_name",
      title: "Title",
      className: "dt-left",
      sortable: true,
      render: (text, record) => {
        return record.title
          ? record.title
          : record.first_name + " " + record.last_name;
      },
    },
    {
      dataIndex: "no_of_doc",
      title: "Document",
      className: "dt-center",
      sortable: true,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      className: "dt-center",
      render: (branch) => branch?.branch_name ?? "-",
      sortable: true,
    },
    {
      title: "Status",
      className: "dt-center",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case 0:
            return "Pending";
          case 1:
            return "In Progress";
          case 2:
            return "Complete";
          default:
            return "-";
        }
      },
      sortable: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => handleEdit(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteRawCase(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const viewName = async (pickedup) => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/documentconfiguration/addnewdocumentconfig/", {
        state: pickedup,
      });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/documentconfiguration/addnewdocumentconfig/", {
        state: pickedup,
      });
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/documentconfiguration/addnewdocumentconfig/", {
        state: pickedup,
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    <h5>Picked Cases Management</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="button-box">
                    <ul className="">
                      <li>
                        <div onClick={handleShow} className="blue-btn">
                          + Add New
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <section id="pickedCases">
              <AntdTable
                dataSource={pickedCases}
                columns={columns}
                pageNo={pageNo}
                pageSize={pageSize}
                setPageNo={setPageNo}
                setPageSize={setPageSize}
                total={total}
              />
            </section>
          </div>
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Picked Case</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="caseName"
                  value={caseName}
                  onChange={handleInputChange}
                  required
                  autoFocus
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Number"
                  name="mobile"
                  value={mobile}
                  onChange={handleInputChange}
                  required
                  autoFocus
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>User</Form.Label>
                <Form.Select
                  required
                  autoFocus
                  onChange={(e) => setUserId(e.target.value)}
                >
                  <option selected disabled>
                    Select User
                  </option>
                  {userList.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.first_name} {user.middle_name} {user.last_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Branch</Form.Label>
                <Form.Select
                  required
                  autoFocus
                  onChange={(e) => setBranchId(e.target.value)}
                >
                  <option selected disabled>
                    Select Branch
                  </option>
                  {branchList.map((branch, index) => (
                    <option key={index} value={branch.id}>
                      {branch.branch_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Document</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Enter Number"
                  accept="image/*"
                  onChange={(e) => handleFileInputChange(e)}
                  required
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={createCase}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal> */}
    </>
  );
}

export default PickedUpCases;
