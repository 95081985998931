import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/layouts/Sidebar";
import Header from "../../components/layouts/Header";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/Api";
import Table from "../../components/Table";

import { useToast } from "../../utils/helpers/CommFun";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { API_URL, Role, STATUS_TYPE } from "../../utils/constant/Constant";
import AntdTable from "../../components/AntdTable/Table";
import {
  deletePayment,
  fetchAllPayments,
  paymentStatusReset,
} from "../../redux/slices/paymentSlice";
import { useDispatch, useSelector } from "react-redux";

function PaymentManagement() {
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payment.payments);
  const total = useSelector((state) => state.payment.total);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllPayments(body));
  };

  //Delete Document
  const handleDeletePayment = async (document) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete Payment?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = {
          payment_id: document.id,
        };
        dispatch(deletePayment(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => {});
      }
    });
  };

  const onredirect = async (payment) => {
    const data = {
      payment_id: payment.id,
    };
    if (userData.role == Role.MASTER_VENDOR) {
      navigate("/admin/payment/add/", {
        state: data,
      });
    }else if (userData.role == Role.BRANCH_MANAGER) {
      navigate("/user/payment/add/", {
        state: data,
      });
    } else {
      navigate("/login");
    }
  };

  const columns = [
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    { dataIndex: "title", title: "Title", className: "dt-left" },
    {
      title: "Client",
      className: "dt-center",
      dataIndex: "client",
      render: (client) => (client ? client.name : " "),
    },
    {
      dataIndex: "payment_type",
      render: (payment_type) => {
        switch (payment_type) {
          case "1":
            return "Applicant";
          case "2":
            return "Case";
          case "3":
            return "Point";

          default:
            return "-";
        }
      },
      title: "Payment Type",
      className: "dt-center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.user_id.id === userData.id ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditOutlined
              style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
              onClick={() => onredirect(record)} // Function to handle edit
            />
            <DeleteOutlined
              style={{ color: "#D92E38", cursor: "pointer" }}
              onClick={() => handleDeletePayment(record)} // Function to handle delete
            />
          </div>
        ) : (
          "-"
        ),
      className: "dt-center",
    },
  ];

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    <h5>Payment Management</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="button-box">
                    <ul className="">
                      <li>
                        <Link
                          to={
                            [Role.MASTER_VENDOR].some(
                              (role) => userData.role == role
                            )
                              ? "/admin/payment/add"
                              : [Role.BRANCH_MANAGER].some(
                                  (role) => userData.role == role
                                )
                              ? "/user/payment/add"
                              : ""
                          }
                          className="blue-btn"
                        >
                          + Add New
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <AntdTable
              dataSource={payments}
              columns={columns}
              pageNo={pageNo}
              pageSize={pageSize}
              setPageNo={setPageNo}
              setPageSize={setPageSize}
              total={total}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentManagement;
