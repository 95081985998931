import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/layouts/Sidebar'
import Header from '../../components/layouts/Header'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../../src/assets/css/custom-style.css'

const Privacy = () => {
    const [editorData, setEditorData] = useState('');

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
    };
    return (
        <>
           
                        <div className="main-contant">
                            <div className="container-fluid">
                                <div className="contant-box">
                                    <div className="sub-header">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="title-box">
                                                    <h5>Privacy Policy</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-body">
                                        <form action="" >
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div className="form-box">
                                                        <label className="form-label">
                                                            Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder=""
                                                            required
                                                            autoFocus
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-box">
                                                        <label className="form-label">
                                                            Description
                                                        </label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={editorData}
                                                            onChange={handleEditorChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="cta-button-box">
                                                        <button type="button"
                                                            className="btns submit-btn gray-btn">Cancel
                                                        </button>
                                                        <button type="button"
                                                            className="btns submit-btn">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                
        </>
    )
}

export default Privacy
