import React, { useState } from "react";
import { Table, Input, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "antd/dist/reset.css"; // Import Ant Design styles

const getColumnSearchProps = (dataIndex, render) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <div>
        <a onClick={() => confirm()} style={{ marginRight: 8 }}>
          Search
        </a>
        <a onClick={() => clearFilters()}>Reset</a>
      </div>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) => {
    const recordValue = record[dataIndex];
    const renderedValue = render ? render(recordValue, record) : recordValue;

    return renderedValue
      ? renderedValue.toString().toLowerCase().includes(value.toLowerCase())
      : false;
  },
});

const AntdTable = ({ columns, dataSource, pageNo, pageSize, setPageNo, setPageSize, total, onRowClick }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  // const [current, setCurrent] = useState(currentPage);

  const processedColumns = columns.map((col) => {
    let newCol = { ...col };

    // Add sorting functionality if enabled in the column definition
    if (col.sortable) {
      newCol.sorter = (a, b) => {
        const aValue = col.render ? col.render(a[col.dataIndex], a) : a[col.dataIndex];
        const bValue = col.render ? col.render(b[col.dataIndex], b) : b[col.dataIndex];

        if (typeof aValue === "number" && typeof bValue === "number") {
          return aValue - bValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return 0; // Prevents sorting if types are different or not handled
        }
      };
    }

    // Add searching functionality if enabled in the column definition
    // if (col.searchable) {
    //   newCol = { ...newCol, ...getColumnSearchProps(col.dataIndex, col.render) };
    // }

    return newCol;
  });

  const onChange = (page, pageSize) => {
    setPageNo(page);
    setPageSize(pageSize);
  };

  // const onShowSizeChange = (page) => {

  //   console.log("pageeeeeeeee sizeeeeeee", page);
  //   setPageSize(page);
  // };

  return (
    <>
      <Table
        columns={processedColumns}
        dataSource={dataSource}
        pagination={false}
        onRow={
          onRowClick
            ? (record) => ({
              onClick: () => {
                onRowClick(record); // Call the passed function
                setSelectedRowKey(record.id); // Update selected row key
              },
              style: {
                backgroundColor: selectedRowKey === record.id ? "#e6f7ff" : "#ffffff",
              }, // Conditional styling
            })
            : undefined
        }
      />
      <div className="m-3"></div>
      <Pagination
        align="end"
        total={total}
        // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        defaultPageSize={pageSize}
        current={pageNo}
        onChange={onChange}
      />
    </>
  );
};

export default AntdTable;
