import React, { useEffect, useState } from "react";
import DynamicForm from "../../../components/DynamicForm/DynamicForm";
import { useToast } from "../../../utils/helpers/CommFun";
import { API_URL, STATUS_TYPE } from "../../../utils/constant/Constant";
import api from "../../../services/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPincodes,
  pincodeStatusReset,
} from "../../../redux/slices/pincodeSlice";
import { addMasterBranchPincode } from "../../../redux/slices/vendorBranchSlice";

const BranchAreaForm = ({ setActiveKey, formValues, FetchBranchDetails }) => {
  const dispatch = useDispatch();
  const { pincodes, status: pincodeStatus } = useSelector(
    (state) => state.pincode
  );

  useEffect(() => {
    dispatch(pincodeStatusReset());
  }, [dispatch]);

  useEffect(() => {
    if (pincodeStatus === STATUS_TYPE.IDLE) {
      dispatch(fetchAllPincodes({}));
    }
  }, [dispatch, pincodeStatus]);

  const [initialValue, setInitialValue] = useState({
    cl_pincode: formValues?.clPincodes.map((item) => item.id),
    ocl_pincode: formValues?.oclPincodes.map((item) => item.id),
  });
  const areaFormConfig = [
    {
      label: "CL Area",
      name: "cl_pincode",
      type: "multiple-select",
      options: pincodes.map((pincode) => ({
        value: pincode.id,
        label: pincode.pincode,
      })),
      colSpan: 24,
    },
    {
      label: "OCL Area",
      name: "ocl_pincode",
      type: "multiple-select",
      options: pincodes.map((pincode) => ({
        value: pincode.id,
        label: pincode.pincode,
      })),
      colSpan: 24,
    },
  ];

  //HANDLE FIELD CHANGE
  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => ({ ...prev, ...changedValues }));
  };
  const addPincodes = async (values) => {
    const body = {
      ...values,
      branch_id: parseInt(formValues.branchId),
    };
    dispatch(addMasterBranchPincode({ body, isUpdate: true }))
      .unwrap()
      .then((response) => {
        FetchBranchDetails();
        setActiveKey("1");
      })
      .catch((error) => {});
  };

  return (
    <>
      <DynamicForm
        config={areaFormConfig}
        initialValues={initialValue}
        handleFieldChange={handleFieldChange}
        onFinish={(data) => addPincodes(data)}
        onCancel={() => setActiveKey("2")}
        submitText="Save"
      />
    </>
  );
};

export default BranchAreaForm;
