import React, { useEffect, useState } from "react";
import Header from "../components/layouts/Header";
import Sidebar from "../components/layouts/Sidebar";
import api from "../services/Api";
import { useNavigate } from "react-router-dom";
import { createUrlObj, useToast } from "../utils/helpers/CommFun";
import {
  formatDate,
  validateNum,
  validateStr,
  validateEmail,
} from ".././utils/helpers/CommFun";
import { API_URL, Role } from "../utils/constant/Constant";
import DynamicForm from "../components/DynamicForm/DynamicForm";

function Profile() {
  
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const [initialValue, setInitialValue] = useState(null);

  const [profileData, setprofileData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    role: "",
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const navigate = useNavigate();
  const Toast = useToast();

  const handleImageChange = (event, setPreview) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  //Get the Profile
  const getProfile = async () => {
    try {
      const body = {
        user_id: JSON.parse(userData.id),
      };
      const response = await api.post(API_URL.GET_USER, body, token);
      if (response.status === 200) {
        setInitialValue({
          userId: response.result.id,
          firstName: response.result.first_name,
          middleName: response.result.middle_name,
          lastName: response.result.last_name,
          email: response.result.email,
          mobile_01: response.result.mobile,
          role: setroleName(response.result.role),
          logoPhoto: userData.logo ? createUrlObj("logoPhoto", userData.logo) : undefined,
          signature: userData.signature ? createUrlObj("signature", userData.signature) : undefined,
          profileImage: userData.profile_image ? createUrlObj("profileImage", userData.profile_image) : undefined,
        })
        setprofileData({
          firstName: response.result.first_name,
          middleName: response.result.middle_name,
          lastName: response.result.last_name,
          email: response.result.email,
          mobile_01: response.result.mobile,
          role: response.result.role,
        });
        setLogoPreview(response.result.logo);
        setSignaturePreview(response.result.signature);
        setProfileImage(response.result.profile_image);
      } else {
        console.error("Failed to fetch client list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const checkValidationForProfile = () => {
    if (!validateNum(profileData.mobile, 10)) {
      Toast.fire({
        icon: "error",
        title: "Mobile No Required",
      });
      return false;
    }
    if (!validateEmail(profileData.email)) {
      Toast.fire({
        icon: "error",
        title: "Email Required",
      });
      return false;
    }

    return true;
  };
  //update profile
  const updateProfile = async (values) => {
    try {
      const body = {
        // user_id: JSON.parse(userData.id),
        // name: profileData.name,
        // email: profileData.email,
        // mobile: profileData.mobile,
        // role: profileData.role,
        // logo: logoPreview,
        // profile_image: profileImage,
        // signature: signaturePreview,
      };
      const formData = new FormData();

      formData.append('user_id', values.userId ?? "");
      formData.append('first_name', values.firstName);
      formData.append('middle_name', values.middleName ?? "");
      formData.append("last_name", values.lastName ?? "");
      formData.append("mobile", values.mobile_01 ?? "");
      const appendFile = async (key, file) => {
        if (file) {
          formData.append(key, file[0].originFileObj);
        }
      };
      appendFile('profile_image', values.profileImage ?? "");
      appendFile('logo', values.logoPhoto ?? "");
      appendFile('signature', values.signature ?? "");
      let response;
      if (values.userId) {
        response = await api.postFormData(API_URL.UPDATE_USER, formData, token);
      }
      if (response.status == 200) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
      } else {
        Toast.fire({
          icon: "warning",
          title: response.message,
        });
        return;
      }
      if (userData.role == Role.SUPER_ADMIN) {
        navigate("/super-admin/dashboard");
      } else if (userData.role == Role.BANKS || userData.role == Role.MASTER_VENDOR) {
        navigate("/admin/dashboard");
      } else if (
        userData.role == Role.BRANCH_MANAGER ||
        userData.role == Role.TEAM_LEAD ||
        userData.role == Role.BACK_OFFICE
      ) {
        navigate("/user/dashboard");
      } else {
        navigate("/login");
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const onredirect = async () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/dashboard");
    } else if (userData.role == Role.BANKS || userData.role == Role.MASTER_VENDOR) {
      navigate("/admin/dashboard");
    } else if (userData.role == Role.BRANCH_MANAGER || userData.role == Role.TEAM_LEAD || userData.role == Role.BACK_OFFICE) {
      navigate("/user/dashboard");
    } else {
      navigate("/login");
    }
  };

  // Function to update state values individually
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Define your validations
    const validations = {
      mobile: /^\d{0,10}$/,
      name: /^[A-Za-z\s.'-]*$/,
      email: /^/,
    };

    if (validations.hasOwnProperty(name)) {
      if (validations[name].test(value) || value === "") {
        setprofileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setprofileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  //set role name
  const setroleName = (role) => {
    if (role === Role.SUPER_ADMIN) {
      return "Super Admin";
    } else if (role === Role.BANKS) {
      return "Bank";
    } else if (role === Role.MASTER_VENDOR) {
      return "Master Vendor";
    } else if (role === Role.BRANCH_MANAGER) {
      return "Bank Manager";
    } else if (role === Role.TEAM_LEAD) {
      return "TL";
    } else if (role === Role.BACK_OFFICE) {
      return "Bank Office";
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const rolesWithCommonUsers = [Role.MASTER_VENDOR, Role.BRANCH_MANAGER, Role.TEAM_LEAD, Role.BACK_OFFICE];
  const formConfig = [
    {
      label: userData.role == Role.SUPER_ADMIN ? "Name" : "First Name",
      name: "firstName",
      type: "input",
      rules: [{ required: true, message: "Please Enter First Name" }],
      colSpan: 6,
    },

    rolesWithCommonUsers.includes(userData.role) && {
      label: "Middle Name",
      name: "middleName",
      type: "input",
      colSpan: 6,
    },

    rolesWithCommonUsers.includes(userData.role) && {
      label: "Last Name",
      name: "lastName",
      type: "input",
      colSpan: 6,
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      rules: [
        { required: true, message: "Please enter your email" },
        { type: "email", message: "Please enter a valid email" },
      ],
      colSpan: 6,
      disabled: true,
    },
    {
      label: "Mobile 01",
      name: "mobile_01",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Mobile 1" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 6,
    },
    {
      label: "Role",
      name: "role",
      type: "input",
      colSpan: 6,
      disabled: true,
    },
    {
      label: 'Profile Image',
      name: 'profileImage',
      accept: ".jpg,.jpeg,.png",
      type: 'upload',
      colSpan: 6
    },
    (userData.role === Role.SUPER_ADMIN) && {
      label: 'Logo',
      name: 'logoPhoto',
      accept: ".jpg,.jpeg,.png",
      type: 'upload', colSpan: 6
    },
    (userData.role === Role.BRANCH_MANAGER || userData.role === Role.TEAM_LEAD) && {
      label: 'Signature',
      name: 'signature',
      accept: ".jpg,.jpeg,.png",
      type: 'upload', colSpan: 6
    },
    {
      label: "User Id",
      name: "userId",
      type: "hidden",
    },

  ];


  return (
          <div className="main-contant">
            <div className="container-fluid">
              <div className="contant-box">
                <div className="sub-header">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="title-box">
                        <h5>Profile</h5>
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <section id="client-management-form">
                  <div className="add_user-box">
                    <div className="add_user-form">
                      <DynamicForm config={formConfig} initialValues={initialValue} onFinish={updateProfile} onCancel={onredirect} />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
     
  );
}

export default Profile;
