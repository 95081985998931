import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Sidebar from "../../components/layouts/Sidebar";
import Header from "../../components/layouts/Header";
import {
  useToast,
  jsonParser,
  convertNames,
} from "../../utils/helpers/CommFun";
import api from "../../services/Api";

import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import axios from "axios";
import { HolderOutlined, DeleteOutlined } from "@ant-design/icons";
import { API_URL, Role } from "../../utils/constant/Constant";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { CSS } from "@dnd-kit/utilities";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Button, Table } from "antd";

function AddNewPayment() {
  //Localstorage needs
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));

  //Doc ID for Update
  const location = useLocation();
  const [paymentId, setPaymentId] = useState(
    location.state?.payment_id || null
  );

  //Form InitialValues
  const [initialValue, setInitialValue] = useState(null);
  //For Lists of selection
  const [clientList, setClientList] = useState([]);
  const [pointList, setPointList] = useState([]);
  const [verificationType, setVerificationType] = useState("");

  const [template, setTemplate] = useState([]);
  const navigate = useNavigate();
  const [masterBranchList, setMasterBranchList] = useState([]);


  const Toast = useToast();

  const closeRef = useRef();
  const closeModal = () => closeRef.current.click();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const openRef = useRef();
  const openModal = () => openRef.current.click();
  const RowContext = React.createContext({});
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setTemplate((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  //Clears/Resets the Fields
  const initialFormState = {
    points_id: null,
    clrate: "",
    oclrate: "",
    // add other fields if needed
  };
  const clear = () => {
    setInitialValue(initialFormState);
  };
  const onCancelButtonClick = () => {
    navigate(-1);
    clear();
  };

  //Get the client list
  const getClientList = async () => {
    try {
      setClientList([]);
      const body = {
        parent_id: userData.role == 3 ? userData.id : userData.parent_id,
      };
      const response = await api.post(API_URL.GET_ALL_CLIENT, body, token);
      if (response.status === 200) {
        setClientList(response.result.data);
      } else {
        console.error("Failed to fetch client list");
      }
    } catch (error) {
      console.error("An error occurred while fetching the Client list", error);
    } finally {
    }
  };

  const getPointList = async (appId) => {
    try {
      setPointList([]);
      const response = await api.post(API_URL.GET_DOC_POINTS, {}, token);
      if (response.status === 200) {
        if (response.result.length >= 1) {
          setPointList(response.result);
        }
      } else {
        console.error("Failed to fetch Point list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const createPaymentData = async () => {
    if (!initialValue.points_id) {
      Toast.fire({ icon: "error", title: "Verification type required" });
      return false;
    }

    if (!initialValue.clrate) {
      Toast.fire({ icon: "error", title: "CL rate required" });
      return false;
    }

    if (!initialValue.oclrate) {
      Toast.fire({ icon: "error", title: "OCL rate required" });
      return false;
    }

    if (template.some((item) => item.points_id === initialValue.points_id)) {
      Toast.fire({
        icon: "error",
        title: "Verification type already exists",
      });
      clear();
      return false;
    }
    setTemplate((prevTemplate) => [...prevTemplate, initialValue]);
    setInitialValue((prev) => ({ ...prev, field: null, options: null }));
    clear();
  };
  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{
          cursor: "move",
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const getMasterBranchList = async () => {
    try {
      let url =
        userData.role == Role.TEAM_LEAD
          ? API_URL.GET_USER_MASTER_BRANCH
          : API_URL.GET_ALL_MASTER_BRANCH;
      const response = await api.post(url, {}, token);
      if (response.status === 200) {
        let masterBranchList = response.result.data.map((branch) => ({
          value: branch.id,
          label: branch.name,
        }));

        setMasterBranchList(masterBranchList);
      } else {
        console.error("Failed to fetch client list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const deleteField = async (data) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete Payment Detail?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (data.id) {
            // Handle API call to delete item with `data.id`
            const body = {
              payment_detail_id: parseInt(data.id, 10),
            };
            const response = await api.post(
              API_URL.REMOVE_PAYMENT_DETAIL,
              body,
              token
            );

            if (response.status === 200) {
              Toast.fire({
                icon: "success",
                title: "Successfully deleted.",
              });
              setTemplate((prevTemplate) => {
                // Filter out the item with matching data
                const updatedTemplate = prevTemplate.filter(
                  (item) => item.id !== data.id
                );
                return updatedTemplate;
              });
            } else {
              Toast.fire({
                icon: "error",
                title: "Failed to delete.",
              });
            }
          } else {
            // Remove item from local `template` state
            const delField = template.splice(template.indexOf(data), 1);
            if (template.length <= 0) {
              setTemplate([]);
            } else {
              setTemplate((template) => [...template]);
            }

            Toast.fire({
              icon: "success",
              title: "Successfully deleted.",
            });
          }
        } catch (error) {
          console.error("Error deleting field:", error);
          Toast.fire({
            icon: "error",
            title: "An error occurred.",
          });
        }
      }
    });
  };

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => index + 1, // Displays row index starting from 1
      className: "dt-center",
    },

    {
      dataIndex: "points_id", // Specifies the field name from your data source
      title: "Verification Type",
      className: "dt-center",
      render: (points_id) => {
        // Use render for custom cell content
        switch (points_id) {
          case "1":
            return "Residence Verification";
          case "2":
            return "Employment Verification";
          case "3":
            return "Business Verification";
          case "4":
            return "Payslip Verification";
          case "5":
            return "IT Returns Verification";
          case "6":
            return "Bank Statement Verification";
          case "7":
            return "Form 16";
          case "8":
            return "Form 26 AS";
          case "9":
            return "GSTN Certificate";
          case "10":
            return "GST Returns";
          case "11":
            return "Udyam Registration Certificate";
          case "12":
            return "Shop Act License Verification";
          case "13":
            return "Tele Verification Report";
          case "14":
            return "Balance Sheet/Financials";
          case "15":
            return "Quotation Verification";
          case "16":
            return "Car Dealer Verification";
          case "17":
            return "Employer Verification";
          case "18":
            return "Identity Verification AADHAR";
          case "19":
            return "Identity Verification PAN";
          case "20":
            return "Builder Verification";
          case "21":
            return "BRT";
          case "22":
            return "Pool Purchase";
          case "23":
            return "Partial Disbursement";
          default:
            return "-";
        }
      },
    },
    {
      dataIndex: "cl_rate", // Use a single field name here
      title: "CL Rate",
      className: "dt-center",
      render: (cl_rate, record) => {
        // Return cl_rate if it's available, otherwise return clrate
        return cl_rate !== undefined ? cl_rate : record.clrate;
      },
    },
    {
      dataIndex: "ocl_rate", // Use a single field name here
      title: "OCL Rate",
      className: "dt-center",
      render: (ocl_rate, record) => {
        // Return cl_rate if it's available, otherwise return clrate
        return ocl_rate !== undefined ? ocl_rate : record.oclrate;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => deleteField(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];
  const loadValues = async () => {
    try {
      const body = {
        payment_id: paymentId,
      };
      const response = await api.post(
        API_URL.GET_PARTICULAR_PAYMENT,
        body,
        token
      );
      const obj = response.result.payment;
      if (response.status === 200) {
        setInitialValue({
          client: obj.client_id,
          paymentId: paymentId,
          paymentType: parseInt(obj.payment_type),
          clrate: obj.cl_rate || "",
          oclrate: obj.ocl_rate || "",
          title: obj.title,
          assign_vendor_branch: response.result.branchIds
            ? response.result.branchIds
            : [],
        });
        setVerificationType(parseInt(obj.payment_type));
        setTemplate(response.result.payment_details);
      } else {
        console.error("Failed to fetch client list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const saveType = async (val) => {
    setVerificationType(val);
  };

  const clientType = async (val) => {
    if (verificationType === 3) {
      setTemplate([]);
    }
  };

  useEffect(() => {
    getClientList();
    getPointList();
    getMasterBranchList();
    if (paymentId) {
      loadValues();
    }
  }, []);

  const createPayment = async (values) => {
    const body = {
      master_id: userData.id,
      client_id: parseInt(values.client),
      payment_type: values.paymentType,
      title: values.title,
      template: template,
      assign_vendor_branch: values.assign_vendor_branch,
      cl_rate: values.clrate,
      ocl_rate: values.oclrate,
    };

    if (values.paymentId) {
      body.payment_id = values.paymentId; // Ensure paymentId is correctly included
    }

    const response = await api.post(
      values.paymentId ? API_URL.UPDATE_PAYMENT : API_URL.ADD_PAYMENT,
      body,
      token
    );
    if (response.status === 200) {
      Toast.fire({
        icon: "success",
        title: response.message,
      });
    } else {
      Toast.fire({
        icon: "error",
        title: response.message,
      });
    }
    navigate(userData.role == 3 ? "/admin/payment" : "/user/payment");
  };

  useEffect(() => {}, [initialValue?.report]);

  const formConfig = [
    {
      label: "Title",
      name: "title",
      type: "input",
      rules: [{ required: true, message: "Please enter title" }],
      colSpan: 24,
    },
    {
      label: "Client",
      name: "client",
      type: "select",
      rules: [{ required: true, message: "Please Select One Option" }],
      options: clientList.map((item) => ({
        value: item.id,
        label: `${item.name} (${item.short_code})`,
      })),
      onChange: (val) => clientType(val),
      colSpan: 12,
      disabled: !!paymentId,
    },
    {
      label: "Assign Branch",
      name: "assign_vendor_branch",
      type: "multiple-select",
      options: masterBranchList,
      colSpan: 12,
    },
    {
      label: "Payment On",
      name: "paymentType",
      type: "select",
      rules: [
        {
          required: true,
          message: "Please Select One Option",
        },
      ],
      options: [
        { value: 1, label: "Applicant" },
        { value: 2, label: "Case" },
        { value: 3, label: "Verification Type" },
      ],
      onChange: (val) => saveType(val),
      colSpan: 12,
      disabled: !!paymentId,
    },
    verificationType == 3 && {
      label: "Verification Type",
      name: "points_id",
      type: "select",
      rules: [{ message: "Please Select One Option" }],
      options: pointList.map((item) => ({ value: item.id, label: item.name })),
      colSpan: 12,
    },
    {
      label: "CL Rate",
      name: "clrate",
      type: "input",
      rules: [{ required: verificationType === 3 ? false : true }],
      colSpan: 12,
    },
    {
      label: "OCL Rate",
      name: "oclrate",
      type: "input",
      rules: [{ required: verificationType === 3 ? false : true }],
      colSpan: verificationType === 3 ? 11 : 12,
    },
    verificationType == 3 && {
      label: " ",
      name: "",
      type: "add-button",
      onClick: () => {
        createPaymentData();
      },
      colSpan: 1,
    },
    {
      label: "paymentId",
      name: "paymentId",
      type: "hidden",
      initialValue: paymentId,
    },
  ];
  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Payment Management</h5>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          <section id="client-management-form">
            <div className="add_user-box">
              <div className="add_user-form">
                <DynamicForm
                  config={formConfig}
                  onFinish={createPayment}
                  initialValues={initialValue}
                  handleFieldChange={handleFieldChange}
                  onCancel={onCancelButtonClick}
                />
              </div>
            </div>
          </section>

          <div className="sub-header sub-header-2">
            <div>
              {verificationType === 3 && (
                <div>
                  <div className="col-md-8">
                    <div className="title-box">
                      <h5>Payment On Verification Type</h5>
                    </div>
                  </div>

                  <DndContext
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                  >
                    <SortableContext
                      items={template.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <Table
                        dataSource={template}
                        columns={columns}
                        rowKey="id"
                        components={{
                          body: {
                            row: Row,
                          },
                        }}
                      />
                    </SortableContext>
                  </DndContext>

                  {/* <Table
                    className="Doc-config"
                    data={template}
                    columns={columns}
                    onupdate={openUpdateFieldModal}
                    isReorderable={true}
                    setData={setTemplate}
                    ondelete={deleteField}
                  /> */}
                </div>
              )}
            </div>

            <div
              className="d-none"
              ref={openRef}
              data-bs-toggle="modal"
              data-bs-target="#reportModal"
            >
              Preview
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewPayment;
