
import React, { useEffect, useRef, useState } from "react";
import { Select, Table, Input } from "antd";
import { API_URL } from '../../utils/constant/Constant';
import api from '../../services/Api';


function TargetManagement() {
  const [clientList, setClientList] = useState([]);
  const [val, setVal] = useState([]);
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const { Option } = Select;
  const expandedRowRender2 = () => {
    // const columns = [
    //   {
    //     dataIndex: "date",
    //     key: "date",
    //   },
    //   {
    //     dataIndex: "name",
    //     key: "name",
    //   },
    //   {
    //     key: "state",
    //     render: () => <Badge status="success" text="Finished" />,
    //   },
    //   {
    //     dataIndex: "upgradeNum",
    //     key: "upgradeNum",
    //   },
    //   {
    //     key: "operation",
    //     render: () => (
    //       <Space size="middle">
    //         <a>Pause</a>
    //         <a>Stop</a>
    //         <Dropdown
    //           menu={{
    //             items,
    //           }}
    //         >
    //           <a>
    //             More <DownOutlined />
    //           </a>
    //         </Dropdown>
    //       </Space>
    //     ),
    //   },
    // ];
    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i.toString(),
        name: `Mumbai ${i}`,
        August: 83,
        July: 83,
        June: 80,
      });
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
      />
    );
  };

  const getClientList = async () => {
    try {
      const body = { parent_id: userData.id };
      const response = await api.post(API_URL.GET_ALL_CLIENT, body, token);
      if (response.status === 200) {
        setClientList(response.result.data.map(client => ({
          value: client.id,
          label: client.name,
        })));
      } else {
        console.error('Failed to fetch client list');
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const initialData = [];
    for (let i = 0; i < 3; ++i) {
      initialData.push({
        key: i.toString(),
        name: `Crux ${i}`,
        August: "5000", // Initial value
        July: "9000-10000",
        June: "7000-8000",
      });
    }
    setVal(initialData);
  }, []);
  useEffect(() => {
    if (userData) {
      getClientList();
    }
  }, []);
  const expandedRowRender = (record) => {
    // const columns = [
    //   {
    //     dataIndex: "date",
    //     key: "date",
    //   },
    //   {
    //     dataIndex: "name",
    //     key: "name",
    //   },
    //   {
    //     key: "state",
    //     render: () => <Badge status="success" text="Finished" />,
    //   },
    //   {
    //     dataIndex: "upgradeNum",
    //     key: "upgradeNum",
    //   },
    //   {
    //     key: "operation",
    //     render: () => (
    //       <Space size="middle">
    //         <a>Pause</a>
    //         <a>Stop</a>
    //         <Dropdown
    //           menu={{
    //             items,
    //           }}
    //         >
    //           <a>
    //             More <DownOutlined />
    //           </a>
    //         </Dropdown>
    //       </Space>
    //     ),
    //   },
    // ];
    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i.toString(),
        name: `SBI ${i}`,
        August: 250,
        July: 225,
        June: 175,
      });
    }
    return (
      <Table
        columns={columns}
        dataSource={val}
        pagination={false}
        showHeader={false}
        expandable={{
          expandedRowRender: expandedRowRender2,
          //   expandIcon: (props) => (
          //     <span onClick={props.onExpand} style={{ cursor: "pointer" }}>
          //       {props.expanded ? <UpOutlined /> : <DownOutlined />}
          //     </span>
          //   ),
        }}
      />
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width:'24%'
    },
    {
      title: "August",
      dataIndex: "August",
      key: "august",
      width: "24%",
      render: (text, record) => (
        <Input
          value={text}
          style={{ width: 70 }}
          onChange={(e) => handleInputChange(e.target.value, record.key)}
        />
      ),
    },
    {
      title: "July",
      dataIndex: "July",
      key: "July",
      width:'24%'
    },
    {
      title: "June",
      dataIndex: "June",
      key: "June",
      width:'24%'
    },
  ];
    // Define state for selected options
    const [selectedMonth, setSelectedMonth] = useState('August');
      // Handler for user select change
 // Handler for input change in August column
 const handleInputChange = (value, key) => {
  getClientList();
  setVal(prevData =>
    prevData.map(item =>
      item.key === key ? { ...item, August: value } : item
    )
  );
};

// Handler for user select change
const handleUserChange = (value) => {
  getClientList();
};

// Handler for month select change
const handleMonthChange = (value) => {
  setSelectedMonth(value);
};
  const data = [];
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i.toString(),
      name: `Crux ${i}`,
      August: 1000,
      July: 900,
      June: 700,
    });
  }
  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-6">
                <div className="title-box">
                  <h5>Target Management</h5>
                </div>
              </div>
              <div className="col-md-3">
                <Select
                  style={{ width: 200 }}
                  onChange={handleUserChange}
                  placeholder= "Select Client"
                  options={clientList}
                />
              </div>
              <div className="col-md-2">
                <Select 
                  defaultValue={selectedMonth} 
                  style={{ width: 200 }} 
                  onChange={handleMonthChange}
                > 
                  <Option value="January">Jan</Option>
                  <Option value="February">February</Option>
                  <Option value="March">March</Option>
                  <Option value="April">April</Option>
                  <Option value="May">May</Option>
                  <Option value="June">June</Option>
                  <Option value="July">July</Option>
                  <Option value="August">August</Option>
                  <Option value="September">Sept</Option>
                  <Option value="October">Oct</Option>
                  <Option value="November">Nov</Option>
                  <Option value="December">Dec</Option>
                </Select>
              </div>
            </div>
          </div>
          <section id="target-management">
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: expandedRowRender,
                defaultExpandedRowKeys: ["0"],
                //   expandIcon: (props) => (
                //     <span onClick={props.onExpand} style={{ cursor: "pointer" }}>
                //       {props.expanded ? <UpOutlined /> : <DownOutlined />}
                //     </span>
                //   ),
              }}
              dataSource={data}
            />
          </section>
        </div>
      </div>
    </div>
  );
}

export default TargetManagement;
