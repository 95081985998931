export const messages = {
  GET_OTP: "OTP send successfully",
  VALID: "Please enter valid number",
  LOGIN: "Login successfully",
  VALID_ID: "Please enter valid login ID",
  VALID_ACCO: "Please enter valid Account No.",
  VALID_IFSC: "Please enter valid IFSC Code",
  ENTER_ACCO: "Please enter Account No.",
  ENTER_IFSC: "Please enter IFSC Code",
};

export const Role = {
  SUPER_ADMIN: 1,
  BANKS: 2,
  MASTER_VENDOR: 3,
  BRANCH_MANAGER: 4,
  TEAM_LEAD: 5,
  BACK_OFFICE: 6,
  FE_PE: 7,
};
export const API_URL = {
  CHANGE_PASSWORD: "/user/change_password",
  RESET_PASSWORD: "/auth/resetpassword",
  FORGOT_PASSWORD: "/auth/forgetpassword",
  AUTH_DASHBOARD: "/auth/dashboard",
  AUTH_LOGIN: "/auth/login",

  SEARCH_APPLICANT: "/case/search_applicant",
  CREATE_APPLICANT: `/case/create_applicant`,
  DELETE_APPLICANT: `/case/delete_applicant`,

  GET_USER: "/user/get_user",
  GET_ALL_USER: "/user/get_all_user",
  UPDATE_USER: "/user/update_user",
  CREATE_USER: "/user/create_user",
  DELETE_USER: `/user/delete_user`,
  UPDATE_USER_STATUS: "/user/update_status",
  GET_ALL_BRANCHMANAGER: "/user/get_all_branchmanager",
  GET_ALL_TEAMLEAD: "/user/get_all_teamlead",
  GET_ALL_BACKOFFICE: "/user/get_all_backoffice",
  GET_USER_MASTER_BRANCH: "/user/get_user_master_branch",
  GET_USER_CLIENT_BRANCH: "/user/get_user_client_branch",

  ASSIGN_MASTER_BRANCH: "/user/assign_master_branch",
  GET_ALL_MASTER_BRANCH: "/master_branch/get_all_master_branch",
  CREATE_MASTER_BRANCH: "/master_branch/create_master_branch",
  UPDATE_MASTER_BRANCH: "/master_branch/update_master_branch",
  GET_PERTICULAR_MASTER_BRANCH: "/master_branch/get_particular_master_branch",
  DELETE_MASTER_BRANCH: "/master_branch/delete_master_branch",
  ADD_MASTER_BRANCH_HOURS: "/master_branch/add_branch_hours",
  ADD_MASTER_BRANCH_PINCODE: "/master_branch/add_branch_pincode",
  GET_MASTER_BRANCH_HOURS: "/master_branch/get_branch_hours",
  GET_MASTER_BRANCH_PINCODE: "/master_branch/get_branch_pincode",

  GET_BRANCH_PINCODE: "/master_branch/get_branch_pincodes",
  GET_ALL_BRANCH: "/branch/get_all_branch",
  UPDATE_BRANCH: "/branch/update_branch",
  CREATE_BRANCH: "/branch/create_branch",
  UPDATE_BRANCH_STATUS: "/branch/update_status",
  GET_BRANCH: "/branch/get_branch",
  DELETE_BRANCH: "/branch/delete_branch",
  GET_CLIENT_BRANCH: "/branch/get_client_branch",
  GET_STATES: "/branch/get_states",
  GET_PINCODES: "/branch/get_pincodes",
  GET_VERIFICATION_TYPE: "/case/getverificationtype",
  GET_CASE_POINTS: "/case/get_points",
  ADD_POINTS: "/case/add_points",
  UPDATE_POINTS: "/case/update_points/",
  DELETE_POINTS: `/case/delete_points`,
  GET_FIELD_EXECUTIVE: "/case/get_field_executive",
  GET_CLIENT_DATA: "/branch/client_payment",

  DOC_CONFIG: "/document/doc_config",
  GET_DOC_POINTS: "/document/get_points",
  GET_DOC_FIELDS: "/document/get_fields",
  GET_DOC_COPY_FROM: "/document/get_copy_from",
  UPLOAD_REPORT: "/document/upload_report",
  ADD_CLIENT_DOC: "/document/add_client_document",
  UPDATE_CLIENT_DOC: "/document/update_client_document",
  GET_CLIENT_DOC_DETAILS: "/document/get_client_document_details",
  GET_CLIENT_DOC: "/document/get_client_document",
  UPDATE_DOC_STATUS: "/document/update_status",
  DELETE_DOC: "/document/delete_doc",
  GET_CHAT: "/message/get_chat",
  SEND_MESSAGE: "/message/send_message",
  GET_CASE_DETAILS: "/case/case_details",
  DELETE_CASE: `/case/delete_case`,
  CREATE_CASE_FROM_EXCEL: "/case/create_case_from_excel",
  GET_ALL_CASE: "/case/get_all_case",
  GET_CASE_APPLICANT: "/case/get_applicant",
  
  GET_ALL_CLIENT: "/client/get_all_client",
  GET_PERTICULAR_CLIENT: "/client/get_particular_client",
  UPDATE_CLIENT: "/client/update_client/",
  CREATE_CLIENT: "/client/create_client/",
  DELETE_CLIENT: "/client/delete_client",
  UPDATE_CLIENT_STATUS: "/client/update_status",

  CREATE_HOLIDAY: "/branch/create_holiday",
  UPDATE_HOLIDAY: "/branch/update_holiday",
  GET_HOLIDAY_LIST: "/branch/get_holiday_list",
  REMOVE_HOLIDAY: "/branch/remove_holiday",

  CREATE_RAW_CASE: "/rawcase/create_raw_case",
  GET_ALL_RAW_CASE: "/rawcase/get_all_raw_case",
  DELETE_RAW_CASE: `/rawcase/delete_raw_case`,
  GET_RAW_CASE_DOC: `/rawcase/get_docs`,
  UPDATE_RAW_CASE: "/rawcase/update_raw_case",
  GET_PARTICULAR_RAW_CASE: "/rawcase/find_particular_raw_case",

  CHECK_USER_BRANCH: "/user/check_user_branch",
  CHECK_USER_PINCODES: "/user/check_user_pincodes",

  CREATE_ZONE: "/zone/create_zone",
  GET_ALL_ZONE: "/zone/get_all_zone",
  GET_ZONE: "/zone/get_zone",
  UPDATE_ZONE: "/zone/update_zone",
  DELETE_ZONE: "/zone/delete_zone",
  GET_ALL_STATE: "/branch/get_all_states ",

  GET_PAYMENT: "/payment/get_all_payments",
  ADD_PAYMENT: "/payment/create_payment",
  REMOVE_PAYMENT: "/payment/remove_payment",
  GET_PARTICULAR_PAYMENT: "/payment/get_particular_payment",
  UPDATE_PAYMENT: "/payment/update_payment",
  REMOVE_PAYMENT_DETAIL: "/payment/remove_detail",

  GET_ALL_PINCODE: "/pincode/get_all_pincode",
  CREATE_PINCODE: "/pincode/create_pincode",
  GET_PARTICULAR_PINCODE: "/pincode/get_particular",
  UPDATE_PINCODE: "/pincode/update_pincode",
  REMOVE_PINCODE: "/pincode/remove_pincode",

  GET_ALL_FIELD: "/field/get_all_field",
  CREATE_FIELD: "/field/create_field",
  GET_PARTICULAR_FIELD: "/field/get_particular_field",
  UPDATE_FIELD: "/field/update_field",
  REMOVE_FIELD: "/field/remove_field",

  GET_ALL_DOC_CATEGORY: "/doc_category/get_all_category",
  CREATE_CATEGORY: "/doc_category/create_category",
  GET_PARTICULAR_CATEGORY: "/doc_category/get_particular_category",
  UPDATE_CATEGORY: "/doc_category/update_category",
  REMOVE_CATEGORY: "/doc_category/remove_category",
};
export const originalVerificationList = [
  { id: 1, name: "Residence Verification" },
  { id: 2, name: "Employment Verification" },
  { id: 3, name: "Business Verification" },
  { id: 4, name: "Payslip Verification" },
  { id: 5, name: "IT Returns Verification" },
  { id: 6, name: "Bank Statement Verification" },
  { id: 7, name: "Form 16" },
  { id: 8, name: "Form 26 AS" },
  { id: 9, name: "GSTN Certificate" },
  { id: 10, name: "GST Returns" },
  { id: 11, name: "Udyam Registration Certificate" },
  { id: 12, name: "Shop Act License Verification" },
  { id: 13, name: "Tele Verification Report" },
  { id: 14, name: "Balance Sheet/Financials" },
  { id: 15, name: "Quotation Verification" },
  { id: 16, name: "Car Dealer Verification" },
  { id: 17, name: "Employer Verification" },
  { id: 18, name: "Identity Verification AADHAR" },
  { id: 19, name: "Identity Verification PAN" },
  { id: 20, name: "Builder Verification" },
  { id: 21, name: "BRT" },
  { id: 22, name: "Pool Purchase" },
  { id: 23, name: "Partial Disbursement" },
];
export const VerList = {
  Residence_Verification: 1,
  Employment_Verification: 2,
  Business_Verification: 3,
  Payslip_Verification: 4,
  IT_Returns_Verification: 5,
  Bank_Statement_Verification: 6,
  Form_16: 7,
  Form_26_AS: 8,
  GSTN_Certificate: 9,
  GST_Returns: 10,
  Udyam_Registration_Certificate: 11,
  Shop_Act_License_Verification: 12,
  Tele_Verification_Report: 13,
  Balance_Sheet_Financials: 14,
  Quotation_Verification: 15,
  Car_Dealer_Verification: 16,
  Employer_Verification: 17,
  IdentityVerification_AADHAR: 18,
  IdentityVerification_PAN: 19,
  BuilderVerification: 20,
  BRT: 21,
  Pool_Purchase: 22,
  Partial_Disbursement: 23,
};
export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const STATUS_TYPE = {
  IDLE:"idle",
  LOADING:"loading",
  SUCCEEDED: "succeeded",
  FAILED:"failed"
}
