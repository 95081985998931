import React, { useState, useEffect } from "react";
import { Select, Table, Input } from "antd";
import { API_URL } from '../../utils/constant/Constant';
import api from '../../services/Api';

function AddNewTarget() {
  const [clientList, setClientList] = useState([]);
  const [val, setVal] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("August");
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const { Option } = Select;

  // Initialize table data
  useEffect(() => {
    const initialData = [];
    for (let i = 0; i < 3; ++i) {
      initialData.push({
        key: i.toString(),
        name: `Crux ${i}`,
        August: "5000", // Initial value
        July: "9000-10000",
        June: "7000-8000",
      });
    }
    setVal(initialData);
  }, []);

  // Fetch client list when userData is available
  useEffect(() => {
    if (userData) {
      getClientList();
    }
  }, []);

  const getClientList = async () => {
    try {
      const body = { parent_id: userData.id };
      const response = await api.post(API_URL.GET_ALL_CLIENT, body, token);
      if (response.status === 200) {
        setClientList(response.result.data.map(client => ({
          value: client.id,
          label: client.name,
        })));
      } else {
        console.error('Failed to fetch client list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handler for input change in August column
  const handleInputChange = (value, key) => {
    getClientList();
    setVal(prevData =>
      prevData.map(item =>
        item.key === key ? { ...item, August: value } : item
      )
    );
  };

  // Handler for user select change
  const handleUserChange = (value) => {
    getClientList();
  };

  // Handler for month select change
  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  // Define table columns
  const columns = [
    {
      title: "Branch Name",
      dataIndex: "name",
      key: "name",
      width: "24%",
      className: "dt-center",
      sortable: true, // Enable sort

    },
    {
      title: "August",
      dataIndex: "August",
      key: "august",
      width: "24%",
      className: "dt-center",
      sortable: true, // Enable sort
    
      render: (text, record) => {
        // Assuming text is the value for the first Input and record.someOtherField is for the second Input
        const text2 = "10000"; // Replace this with the actual field name for the second value
    
        return (
          <>
            <Input
              value={text}
              style={{ width: 70, marginBottom: 5 }}
              onChange={(e) => handleInputChange(e.target.value, record.key, 'text')}
            />
            <span> - </span>

            <Input
              value={text2}
              style={{ width: 70 }}
              onChange={(e) => handleInputChange(e.target.value, record.key, 'text2')}
            />
          </>
        );
      },
    },
    
    {
      title: "July",
      dataIndex: "July",
      key: "july",
      width: "24%",
      className: "dt-center",
      sortable: true, // Enable sort

    },
    {
      title: "June",
      dataIndex: "June",
      key: "june",
      className: "dt-center",
      width: "24%",
      sortable: true, // Enable sort

    },
  ];

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Target Management</h5>
                </div>
              </div>
              <div className="col-md-2">
                <Select
                  style={{ width: 200 }}
                  onChange={handleUserChange}
                  placeholder= "Select Client"
                  options={clientList}
                />
              </div>
              <div className="col-md-2">
                <Select
                  defaultValue={selectedMonth}
                  style={{ width: 150 }}
                  onChange={handleMonthChange}
                >
                  <Option value="January">Jan</Option>
                  <Option value="February">February</Option>
                  <Option value="March">March</Option>
                  <Option value="April">April</Option>
                  <Option value="May">May</Option>
                  <Option value="June">June</Option>
                  <Option value="July">July</Option>
                  <Option value="August">August</Option>
                  <Option value="September">Sept</Option>
                  <Option value="October">Oct</Option>
                  <Option value="November">Nov</Option>
                  <Option value="December">Dec</Option>
                </Select>
              </div>
            </div>
          </div>
          <section id="target-management">
            <Table
              columns={columns}
              dataSource={val} // Use the state variable
            />
          </section>
        </div>
      </div>
    </div>
  );
}

export default AddNewTarget;
