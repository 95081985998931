import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "../slices/userSlice";
import clientReducer from "../slices/clientSlice";
import holidayReducer from "../slices/holidaySlice";
import pincodeReducer from "../slices/pincodeSlice";
import fieldReducer from "../slices/fieldSlice";
import categoryReducer from "../slices/categorySlice";
import paymentReducer from "../slices/paymentSlice";
import vendorBranchReducer from "../slices/vendorBranchSlice";
import clientBranchReducer from "../slices/clientBranchSlice";
import stateCityReducer from "../slices/stateCitySlice";
import pickedCaseReducer from "../slices/pickedCaseSlice";
import CaseReducer from "../slices/caseSlice";

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  client: clientReducer,
  clientBranch: clientBranchReducer,
  vendorBranch: vendorBranchReducer,
  holiday: holidayReducer,
  pincode: pincodeReducer,
  field: fieldReducer,
  category: categoryReducer,
  payment: paymentReducer,
  stateCity: stateCityReducer,
  pickedCase: pickedCaseReducer,
  Case: CaseReducer,
});

// Create store with combined reducer
export const store = configureStore({
  reducer: rootReducer,
});

export default store;
