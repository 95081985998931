import React, { useState, useEffect, useRef } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import api from "../../services/Api";
import { useToast } from "../../utils/helpers/CommFun";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import moment from "moment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import AntdTable from "../../components/AntdTable/Table";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateHoliday,
  deleteHoliday,
  fetchAllHolidays,
  holidayStatusReset,
} from "../../redux/slices/holidaySlice";

function HolidayManagement() {
  const dispatch = useDispatch();
  const holidays = useSelector((state) => state.holiday.holidays);
  const status = useSelector((state) => state.holiday.status);
  const error = useSelector((state) => state.holiday.error);
  const total = useSelector((state) => state.holiday.total);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);

  const user_id = JSON.parse(localStorage.getItem("user_data")).id;
  const [initialValue, setInitialValue] = useState(null);

  const closeRef = useRef();
  const openRef = useRef();

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllHolidays(body));
  };

  const closeModal = () => {
    setIsUpdate(false);
    clear();
    closeRef.current.click();
    setInitialValue(null);
  };
  const openModal = () => {
    openRef.current.click();
  };

  // Clear form fields
  const clear = () => {
    setIsUpdate(false);
    setInitialValue(null);
  };

  // Create or update holiday
  const createHoliday = async (values) => {
    const body = {
      title: values.title,
      date: values.date,
      branch_manager_id: parseInt(user_id),
    };
    if (values.holidayId) body.holiday_id = values.holidayId;
    const isUpdate = values.holidayId ? true : false;
    dispatch(createOrUpdateHoliday({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        fetchData();
        closeModal();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };

  // Open modal for update
  const openModalforUpdate = async (holiday) => {
    setIsUpdate(true);
    setInitialValue({
      title: holiday.title,
      date: dayjs(holiday.date, "YYYY-MM-DD"),
      holidayId: holiday.id,
    });
    openModal();
  };

  // Delete holiday
  const handleDeleteHoliday = async (holiday) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete this holiday?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { holiday_id: holiday.id };
        dispatch(deleteHoliday(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => { });
      }
    });
  };

  const columns = [
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    {
      dataIndex: "title",
      title: "Title",
      className: "dt-left",
      sortable: true,
    },
    {
      dataIndex: "date",
      title: "Date",
      className: "dt-center",
      sortable: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => openModalforUpdate(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteHoliday(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const formConfig = [
    {
      label: "Title",
      name: "title",
      type: "input",
      rules: [{ required: true, message: "Please Enter Title" }],
      colSpan: 24,
    },
    {
      label: "Date",
      name: "date",
      type: "date",
      rules: [{ required: true, message: "Please Enter Date" }],
      colSpan: 24,
    },
    {
      label: "holidayId",
      name: "holidayId",
      type: "hidden",
      initialValue: null,
    },
  ];

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Holiday Management</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="button-box">
                  <ul className="">
                    <li>
                      <Link
                        to="/"
                        className="blue-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#addEditHolidayModal"
                        ref={openRef}
                      >
                        + Add New
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section id="holiday-management">
            <AntdTable dataSource={holidays} columns={columns} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} setPageSize={setPageSize} total={total} />
            <div className="modal fade" id="addEditHolidayModal" tabIndex={-1} aria-labelledby="addEditHolidayModal" data-bs-backdrop="static" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEditHolidayModal">
                      {isUpdate ? "Update Holiday" : "Add New Holiday"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ref={closeRef}
                      onClick={clear}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <DynamicForm
                      config={formConfig}
                      onFinish={createHoliday}
                      initialValues={initialValue}
                      onCancel={closeModal}
                    />

                    {/* <form
                            className="row g-3"

                          >
                            
                              <label htmlFor="holidayName" className="form-label">
                                Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="holidayName"
                                value={holidayName}
                                onChange={(e) => setHolidayName(e.target.value)}
                                required
                              />
                            
                        
                              <label htmlFor="holidayDate" className="form-label">
                                Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="holidayDate"
                                value={holidayDate}
                                onChange={(e) => setHolidayDate(e.target.value)}
                                required
                              />
                  
                          
                            
                                <button type="button" class="btn submit-btn gray-btn"   data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={clear}
                                  ref={closeRef}>Cancel</button>
                                <button type="button" className="btn submit-btn" onClick={(e) => { createHoliday();  }} >Save</button>
                            
                          
                          </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HolidayManagement;
