// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { createOrUpdateData, deleteData, fetchData } from "../utils/apiActions";

export const fetchAllHolidays = fetchData("holiday", API_URL.GET_HOLIDAY_LIST);

export const createOrUpdateHoliday = createOrUpdateData(
  "holiday",
  API_URL.CREATE_HOLIDAY,
  API_URL.UPDATE_HOLIDAY
);

export const deleteHoliday = deleteData("holiday", API_URL.REMOVE_HOLIDAY);

const holidaySlice = createSlice({
  name: "holiday",
  initialState: {
    holidays: [],
    selectedHoliday: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    holidayStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllHolidays.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllHolidays.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.holidays = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllHolidays.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdateHoliday.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateHoliday.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful holiday creation/updation
      })
      .addCase(createOrUpdateHoliday.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteHoliday.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteHoliday.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteHoliday.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { holidayStatusReset } = holidaySlice.actions;
export default holidaySlice.reducer;
