import React, { useState, useEffect } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { createUrlObj, getBase64, useToast } from "../../utils/helpers/CommFun";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { Select } from "antd";
import { data } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllClientBranches } from "../../redux/slices/clientBranchSlice";
import {
  createOrUpdatePickedCase,
  fetchAllPickedCases,
  fetchPerticularPickedCase,
  resetPickedCaseState,
} from "../../redux/slices/pickedCaseSlice";

const UpdateCase = () => {
  const dispatch = useDispatch();
  const { selectedPickedCase, status: pickedCaseStatus } = useSelector(
    (state) => state.pickedCase
  );
  const { clientBranches, status, error, total } = useSelector(
    (state) => state.clientBranch
  );

  const location = useLocation();
  const [pickedId, setPickedId] = useState(location.state?.picked_id || null);
  const navigate = useNavigate();
  const Toast = useToast();

  const { Option } = Select;
  const [initialValue, setInitialValue] = useState();
  const [caseType, setCaseType] = useState("");
  const [branchType, setBranchType] = useState("");
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));

  useEffect(() => {
    dispatch(resetPickedCaseState());
  }, [dispatch]);
  useEffect(() => {
    if (status === STATUS_TYPE.IDLE) {
      dispatch(fetchAllClientBranches({}));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (pickedId) {
      const body = {
        picked_id: pickedId,
      };
      dispatch(fetchPerticularPickedCase(body));
    }
  }, [pickedId]);

  useEffect(() => {
    if (selectedPickedCase) {
      setInitialValue({
        // rawId: selectedPickedCase.id,
        firstName: selectedPickedCase.first_name || "",
        middleName: selectedPickedCase.middle_name || "",
        lastName: selectedPickedCase.last_name || "",
        branchId: selectedPickedCase.branch_id || "",
        pickedId: pickedId,
        title: selectedPickedCase.title || "",
        description: selectedPickedCase.description || "",
        mobile: parseInt(selectedPickedCase.mobile_number) || "",
        selectType: selectedPickedCase.type || "",
        loan_amount: selectedPickedCase.loan_amount || "",
        loan_type: parseInt(selectedPickedCase.loan_type) || 0,
        status:
          selectedPickedCase.status != 0
            ? selectedPickedCase.status
            : undefined,
        // documentFile: parseInt(selectedPickedCase.) // uncomment and fix this line if needed
      });
      setBranchType(selectedPickedCase.branch_id);
      setCaseType(selectedPickedCase.type);
    }
  }, [selectedPickedCase]);

  const branchData = async (val) => {
    if (val) {
      setBranchType(val);
    }
  };

  const getCaseDocuments = async (id) => {
    try {
      const body = {
        raw_case_id: id,
      };
      const response = await api.post(API_URL.GET_RAW_CASE_DOC, body, token);
      if (response.status === 200) {
        let fileArray = [];
        response.result.forEach((element) => {
          fileArray.push(createUrlObj("documentFile", element.document)[0]);
        });
        setInitialValue((prev) => ({
          ...prev,
          documentFile: fileArray,
        }));
      } else {
        console.error("Failed to fetch documents");
      }
    } catch (error) {
    } finally {
    }
  };

  const getFileArray = async (docFiles) => {
    const files = [];
    docFiles.forEach(async (element) => {
      const file = await getBase64(element.originFileObj);
      files.push(file);
    });
    return files;
  };

  const appendFormData = (formData, key, value) => {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  };

  const handleSubmit = async (values) => {

    let files;
    if (values.documentFile) {
      files = await Promise.all(
        values.documentFile.map(async (element) => {
          const file = await getBase64(element.originFileObj);
          return file;
        })
      );
    }
    const body = {
      first_name: values.firstName,
      middle_name: values.middleName,
      last_name: values.lastName,
      pe_id: userData.id,
      raw_case_id: values.id,
      status: values.status,
      loan_type: values.loan_type,
      title: values.title,
      description: values.description,
      loan_amount: values.loan_amount || 0,
      mobile_number: parseInt(values.mobile),
      branch_id: parseInt(values.branchId),
      branch_name: values.branch_name,
      user_id: userData.id,
      type: values.selectType,
      // files: files,
    };
    if (values.pickedId) {
      body.picked_id = values.pickedId;
    }
    // let files = [];
    // values.documentFile.forEach(async (element) => {
    //   const file = await getBase64(element.originFileObj);
    //   files.push(file);
    // });

    // if (values.documentFile.length > 0)
    body.files = files;

    const url = initialValue.pickedId
      ? API_URL.UPDATE_RAW_CASE
      : API_URL.CREATE_RAW_CASE;
    if (initialValue?.rawId) {
      body.id = initialValue.rawId;
      body.status = values.status; //  DISUCSS AND CHANGE
    }
    const isUpdate = initialValue.pickedId ? true : false;

    dispatch(createOrUpdatePickedCase({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        dispatch(fetchAllPickedCases({}));
        navigate("/user/pickedcases");
      })
      .catch((error) => {});
  };

  const onCancelButtonClick = () => {
    navigate(-1);
    clear();
  };
  const clear = () => {
    setInitialValue(null);
  };
  // Handle branch selection
  // const handleBranchChange = (value) => {
  //   // getCaseType(value)
  //   setSelectedBranch(value); // Call your getCaseType function with the selected value
  // };
  const getCaseType = (e) => {
    setCaseType(e);
  };
  const isDisabled = pickedId;
  const formConfig = [
    {
      label: "Select Branch",
      name: "branchId",
      type: "select",
      options: clientBranches.map((branch) => ({
        value: branch.id,
        label: branch.branch_name,
      })),
      colSpan: 8,
      onChange: (val) => branchData(val),
      disabled: !!pickedId,
    },
    branchType && {
      label: "Select Type",
      name: "selectType",
      type: "select",
      rules: [{ required: true, message: "Please Select an Option" }],
      options: [
        // { value: 0, label: "None" },
        { value: 1, label: "Text" },
        { value: 2, label: "Upload Doc" },
        { value: 3, label: "Upload Excel" },
      ],
      colSpan: 8,
      onChange: (val) => getCaseType(val),
      disabled: !!pickedId,
    },
    (caseType == 1 || caseType == 3) && {
      label: "Title",
      name: "title",
      type: "input",
      rules: [{ required: true, message: "Please Enter Title" }],
      colSpan: 8,
      disabled: !!pickedId,
    },
    (caseType == 1 || caseType == 3) && {
      label: "Description",
      name: "description",
      type: "input",
      rules: [{ required: true, message: "Please Enter Title" }],
      colSpan: 8,
      disabled: !!pickedId,
    },
    caseType == 2 && {
      label: "First Name",
      name: "firstName",
      type: "input",
      rules: [{ required: true, message: "Please Enter Applicant Name" }],
      colSpan: 8,
      disabled: !!pickedId,
    },
    caseType == 2 && {
      label: "Middle Name",
      name: "middleName",
      type: "input",
      colSpan: 8,
      disabled: !!pickedId,
    },
    caseType == 2 && {
      label: "Last Name",
      name: "lastName",
      type: "input",
      rules: [{ required: true, message: "Please Enter Last Name" }],
      colSpan: 8,
      disabled: !!pickedId,
    },
    // caseType == 2 && {
    //   label: "Branch",
    //   name: "branchId",
    //   type: "select",
    //   options: branchList.map((branch) => ({
    //     value: branch.id,
    //     label: branch.branch_name,
    //   })),
    //   colSpan: 8,
    //   disabled: !!pickedId,
    // },
    // initialValue?.selectType === 0 && {
    //   label: "Mobile",
    //   name: "mobile",
    //   type: "input",
    //   colSpan: 8,
    //   rules: [
    //     { required: true, message: "Please enter mobile number" },
    //     {
    //       pattern: /^[0-9]{10}$/,
    //       message: "Phone number must be exactly 10 digits",
    //     },
    //   ],
    //   disabled: !!pickedId,
    // },
    (caseType == 1 || caseType == 2) && {
      label: "Loan Type",
      name: "loan_type",
      type: "select",
      options: [
        { value: 1, label: "Home Loan" },
        { value: 2, label: "Car Loan" },
        { value: 3, label: "Business Loan" },
        { value: 4, label: "Personal Loan" },
      ],
      colSpan: 8,
      disabled: !!pickedId,
    },
    (caseType == 1 || caseType == 2) && {
      label: "Loan Amount",
      name: "loan_amount",
      type: "input",
      colSpan: 8,
      disabled: !!pickedId,
    },
    pickedId
      ? {
          label: "Status",
          name: "status",
          type: "select",
          options: [
            { value: 1, label: "In Progress" },
            { value: 2, label: "Completed" },
          ],
          rules: [{ required: true, message: "Please Select One Option" }],
          colSpan: 8,
        }
      : null,
    (caseType == 2 || caseType == 3) && {
      label: "Document",
      name: "documentFile",
      type: "multi-upload",
      colSpan: 8,
      accept: ".jpg,.jpeg,.png",
      disabled: !!pickedId,
    },
    caseType == 2 && {
      label: "rawId",
      name: "rawId",
      type: "hidden",
      colSpan: 8,
      initialValue: data.id,
    },
  ].filter(Boolean);

  const handleFieldChange = (changedValues) => {
    

    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  // useEffect(() => {
  //   if (pickedId) {
  //     loadValues();
  //   }
  // }, []);

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    {pickedId ? (
                      <h5 className="bottom-line">Edit Case</h5>
                    ) : (
                      <h5 className="bottom-line">Add New Case</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <section id="pickedCases">
              <div className="add_user-box">
                <div className="add_user-form">
                  <div className=" row">
                    <div className="col-4">
                      {/* <Form.Item 
                   name="branchId"
                   colon={false}
                   style={{ marginBottom: 0 }} // Adjust margin if needed
                 >
                <Select
        placeholder="Select a  Branch"
        style={{ width: "100%", zIndex: 1 }}
        // className="custom-dropdown" // Use className for styling
        // onChange={(value) => getCaseType(value)} // Pass value directly
        onChange={handleBranchChange}
        disabled={isDisabled}
      >
        {branchList.map((branch) => (
          <Option key={branch.id} value={branch.id}>
            {branch.branch_name}
          </Option>
        ))}
      </Select>
              </Form.Item> */}
                    </div>
                    {/* {selectedBranch && (
                 <div className="col-4">
                 <Form.Item>
                    <Select
                      placeholder="Select an option"
                      style={{ width: "100%", zIndex: 1 }}
                      
                      // className="custom-dropdown " // Use className for styling
                      onChange={(value) => getCaseType(value)} // Pass value directly
                    >
                      <Select.Option value="1">Text</Select.Option>
                      <Select.Option value="2">Upload Document</Select.Option>
                      <Select.Option value="3">Upload Excel</Select.Option>
                    </Select>
                  </Form.Item>
                 </div>
              )} */}
                    <div>
                      <DynamicForm
                        config={formConfig}
                        initialValues={initialValue}
                        onFinish={handleSubmit}
                        onCancel={onCancelButtonClick}
                        handleFieldChange={handleFieldChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCase;
