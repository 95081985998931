import api from '../services/Api'
import { API_URL } from '../utils/constant/Constant';

const AuthService = {
  login: async (credentials) => {
    try {
      const response = await api.post(API_URL.AUTH_LOGIN, credentials);
      if (response.status === 200) {

        // Handle successful login, e.g., store token & user data
        localStorage.setItem('token', response.result.access_token);
        // localStorage.setItem('user', JSON.stringify(response.result.role));
        localStorage.setItem('user_data', JSON.stringify(response.result));
        // localStorage.setItem('userId', response.result.id);

      }
      return response;
    } catch (error) {
      throw error;
    }
  },
  logout: () => {
    localStorage.removeItem('token');
    // localStorage.removeItem('user');
    localStorage.removeItem('user_data');
    // localStorage.removeItem('userId');
    localStorage.removeItem('deviceToken');
    // localStorage.removeItem('profileName');
  },
};

export default AuthService;
