import React, { createContext, useState, useEffect } from 'react';
import UserService from '../services/UserService';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const userList = await UserService.getAllUsers();
      setUsers(userList);
    };
    fetchUsers();
  }, []);

  return (
    <UserContext.Provider value={{ users }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
