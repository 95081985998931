const commonObjects = [
  
  {
    label: "point_id",
    name: "point_id",
    type: "hidden",
    colSpan: 12,
  },
];

//From Field
export const ResidenceVerificationFormConfig = [
  {
    label: "Address",
    name: "address",
    type: "input",
    rules: [{ required: true, message: "Please Enter Address" }],
    colSpan: 12,
  },
  
  ...commonObjects,
];
//From Field
export const EmployementVerificationFormConfig = [
  {
    label: "Name",
    name: "name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Name" }],
    colSpan: 12,
  },
  {
    label: "Address",
    name: "address",
    type: "input",
    rules: [{ required: true, message: "Please Enter Address" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From Field
export const BusinessVerificationFormConfig = [
  {
    label: "Name",
    name: "name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Name" }],
    colSpan: 12,
  },
  {
    label: "Address",
    name: "address",
    type: "input",
    rules: [{ required: true, message: "Please Enter Address" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE or Field
export const PaySlipVerificationFormConfig = [
  {
    label: "Month-Year",
    name: "month_year",
    type: "input",
    rules: [{ required: true, message: "Please Enter Month-year" }],
    colSpan: 12,
  },
  {
    label: "Basic",
    name: "basic",
    type: "input",
    rules: [{ required: true, message: "Please Enter Basic" }],
    colSpan: 12,
  },
  {
    label: "Net Salary",
    name: "net_salary",
    type: "input",
    rules: [{ required: true, message: "Please Enter Net Salary" }],
    colSpan: 12,
  },
  {
    label: "Gross Salary",
    name: "gross_salary",
    type: "input",
    rules: [{ required: true, message: "Please Enter Gross Salary " }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From Field
export const ITReturnVerificationFormConfig = [
  {
    label: "Pan Number",
    name: "pan_no",
    type: "input",
    rules: [{ required: true, message: "Please Enter Pan Number" }],
    colSpan: 12,
  },
  {
    label: "Ward",
    name: "ward",
    type: "input",
    rules: [{ required: true, message: "Please Enter Ward" }],
    colSpan: 12,
  },
  {
    label: "Acknowledgement Number",
    name: "acknowledgement_no",
    type: "input",
    rules: [{ required: true, message: "Please Enter Acknowledgement Number" }],
    colSpan: 12,
  },
  {
    label: "Assement Year",
    name: "assesment_year",
    type: "input",
    rules: [{ required: true, message: "Please Enter Assement Year" }],
    colSpan: 12,
  },
  {
    label: "Total Income",
    name: "total_income",
    type: "input",
    rules: [{ required: true, message: "Please Enter Total Income" }],
    colSpan: 12,
  },
  {
    label: "Tax Paid",
    name: "tax_paid",
    type: "input",
    rules: [{ required: true, message: "Please Enter Tax paid" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE or Field
export const BankStatementVerificationFormConfig = [
  {
    label: "Bank Name",
    name: "bank_name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Bank Name" }],
    colSpan: 12,
  },
  {
    label: "Account Number ",
    name: "account_no",
    type: "input",
    rules: [
      { required: true, message: "Please Enter Account Number" },
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 12,
  },
  {
    label: "Transaction to be verified",
    name: "transaction_to_be_verified",
    type: "input",
    rules: [{ required: true, message: "Transaction to be verified" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE or Field
export const Form_16FormConfig = [
  {
    label: "Name Of Applicant",
    name: "name",
    type: "input",
    rules: [{ required: true, message: "Please Enter  Name" }],
    colSpan: 12,
  },
  {
    label: "TAN of Deductor ",
    name: "tan",
    type: "input",
    rules: [{ required: true, message: "Please Enter TAN of Deductor" }],
    colSpan: 12,
  },
  {
    label: "PAN of Tax Payer",
    name: "pan",
    type: "input",
    rules: [{ required: true, message: " Please Enter PAN of Tax Payer" }],
    colSpan: 12,
  },
  {
    label: "TDS Certificate Number",
    name: "source_of_income",
    type: "input",
    rules: [
      { required: true, message: "Please Enter TDS Certificate Number " },
    ],
    colSpan: 12,
  },
  {
    label: "TDS Amount Deducted as per Cerfificate",
    name: "tds_amount_deducted",
    type: "input",
    rules: [
      {
        required: true,
        message: "Please Enter TDS Amount Deducted as per Cerfificate ",
      },
    ],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE
export const Form_26FormConfig = [
  {
    label: "Name Of Applicant",
    name: "name",
    type: "input",
    rules: [{ required: true, message: "Please Enter  Name" }],
    colSpan: 12,
  },
  {
    label: "TAN of Deductor ",
    name: "tan",
    type: "input",
    rules: [{ required: true, message: "Please Enter TAN of Deductor" }],
    colSpan: 12,
  },
  {
    label: "PAN of Tax Payer",
    name: "pan",
    type: "input",
    colSpan: 12,
  },
  {
    label: "Financial Year",
    name: "financial_year",
    type: "input",
    rules: [{ required: true, message: "Please Enter Financial Year " }],
    colSpan: 12,
  },
  {
    label: "Quarter",
    name: "quarter",
    type: "input",
    rules: [{ required: true, message: "Please Enter Quarter" }],
    colSpan: 12,
  },
  {
    label: "Type of Return",
    name: "return_type",
    type: "input",
    rules: [{ required: true, message: "Please Enter Type of Return" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE
export const GSTNVerificationFormConfig = [
  {
    label: "GST Number",
    name: "gst_number",
    type: "input",
    rules: [{ required: true, message: "Please Enter GST Number" }],
    colSpan: 12,
  },
  {
    label: "Entity Name",
    name: "entity_name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Entity Name" }],
    colSpan: 12,
  },
  {
    label: "Date Of Registration",
    name: "registarion_date",
    type: "date",
    rules: [{ required: true, message: "Please Enter Date Of Registration" }],
    colSpan: 12,
  },

  ...commonObjects,
];
//From BE
export const GSTReturnsVerificationFormConfig = [
  {
    label: "GST Number",
    name: "gst_number",
    type: "input",
    rules: [{ required: true, message: "Please Enter GST Number" }],
    colSpan: 12,
  },
  {
    label: "Entity Name",
    name: "entity_name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Entity Name" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE
export const UdyamRegistrationVerFormConfig = [
  {
    label: "Registration Number",
    name: "register_number",
    type: "input",
    rules: [{ required: true, message: "Please Enter Registration Number " }],
    colSpan: 12,
  },
  {
    label: "Name of Enterprise",
    name: "enterprise_name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Enterprise Name" }],
    colSpan: 12,
  },
  {
    label: "Date Of Incorporation",
    name: "incorporation_date",
    type: "date",
    rules: [{ required: true, message: "Please Enter Date Of Incorporation" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE or Field
export const ShopActLicenseVerFormConfig = [
  {
    label: "Application Number",
    name: "application_number",
    type: "input",
    rules: [{ required: true, message: "Please Enter Application Number " }],
    colSpan: 12,
  },
  {
    label: "Name of Entity",
    name: "entity_name",
    type: "input",
    rules: [{ required: true, message: "Please Enter Entity Name" }],
    colSpan: 12,
  },
  ...commonObjects,
];
//From BE
export const TeleVerificationReportFormConfig = [
  {
    label: "Mobile Number",
    name: "mobile_no",
    type: "number-input",
    rules: [
      { required: true, message: "Please Enter Mobile 1" },
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 12,
    maxLength: 10,
  },
  {
    label: "Alternate Number",
    name: "alternate_no",
    type: "number-input",
    rules: [
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 12,
    maxLength: 10,
  },
  {
    label: "Residence Number",
    name: "residence_no",
    type: "number-input",
    rules: [{ required: true, message: "Please Enter Residence Number " }],
    colSpan: 12,
    maxLength: 10,
  },
  {
    label: "Office Number",
    name: "office_no",
    type: "number-input",
    rules: [{ required: true, message: "Please Enter Residence Number" }],
    colSpan: 12,
    maxLength: 10,
  },
  ...commonObjects,
];
//From BE
export const FinancialsVerFormConfig = [
  {
    label: "Name of the Entity",
    name: "entity_name",
    type: "input",
    rules: [{ required: true, message: "Entity Name Required" }],
    colSpan: 6,
  },
  {
    label: "Pan Number",
    name: "pan_number",
    type: "input",
    rules: [{ required: true, message: "Valid PAN Number Required" }],
    colSpan: 6,
  },
  {
    label: "Assessment Year",
    name: "assessment_year",
    type: "input",
    rules: [{ required: true, message: "Valid Assessment Year Required" }],
    colSpan: 6,
  },

  ...commonObjects,
];
//From  Field
export const QuotationVerFormConfig = [
  {
    label: "Name of Entity",
    name: "entity_name",
    type: "input",
    rules: [{ required: true, message: "Entity Name Required" }],
    colSpan: 6,
  },
  {
    label: "Address",
    name: "address",
    type: "input",
    rules: [{ required: true, message: "Address Required" }],
    colSpan: 6,
  },
  {
    label: "Quotation Number",
    name: "quotation_number",
    type: "input",
    rules: [{ required: true, message: "Quotation Number Required" }],
    colSpan: 6,
  },
  {
    label: "Date of Quotation",
    name: "quotation_date",
    type: "input",
    inputType: "date",
    rules: [{ required: true, message: "Date of Quotation Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
//From  Field
export const CarDealerVerFormConfig = [
  {
    label: "Dealer Name",
    name: "dealer_name",
    type: "input",
    rules: [{ required: true, message: "Dealer Name Required" }],
    colSpan: 6,
  },
  {
    label: "Dealer Address",
    name: "dealer_address",
    type: "input",
    rules: [{ required: true, message: "Dealer Address Required" }],
    colSpan: 6,
  },
  {
    label: "Car Model",
    name: "car_model",
    type: "input",
    rules: [{ required: true, message: "Car Model Required" }],
    colSpan: 6,
  },
  {
    label: "On Road Amount",
    name: "on_road_amount",
    type: "input",
    rules: [{ required: true, message: "Valid On Road Amount Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
//From  Field
export const EmployerVerFormConfig = [
  {
    label: "Employer ID",
    name: "employee_id",
    type: "input",
    rules: [{ required: true, message: "Employer Id Required" }],
    colSpan: 6,
  },
  {
    label: "Address of the Employer",
    name: "address",
    type: "input",
    rules: [{ required: true, message: "Employer Address Required" }],
    colSpan: 6,
  },
  {
    label: "Date of Joining",
    name: "joining_date",
    type: "input",
    inputType: "date",
    rules: [{ required: true, message: "Valid Date of Joining Required" }],
    colSpan: 6,
  },
  {
    label: "Date of Exit",
    name: "exit_date",
    type: "input",
    inputType: "date",
    rules: [{ required: true, message: "Valid Date of Exit Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
//From BE or Field
export const IdentityVerAadharFormConfig = [
  {
    label: "Aadhar Number",
    name: "aadhar_number",
    type: "input",
    rules: [{ required: true, message: "Aadhar Number Required" }],
    colSpan: 6,
  },
  {
    label: "Name in Aadhar",
    name: "aadhar_name",
    type: "input",
    rules: [{ required: true, message: "Aadhar Name Required" }],
    colSpan: 6,
  },
  {
    label: "Address as per Aadhar",
    name: "aadhar_address",
    type: "input",
    rules: [{ required: true, message: "Aadhar Address Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
//From BE or Field
export const IdentityVerPANFormConfig = [
  {
    label: "Pan Number",
    name: "pan_number",
    type: "input",
    rules: [{ required: true, message: "Pan Number Required" }],
    colSpan: 6,
  },
  {
    label: "Name of Pan Holder",
    name: "pan_holder_name",
    type: "input",
    rules: [{ required: true, message: "Pan Holder Name Required" }],
    colSpan: 6,
  },
  {
    label: "Father's Name",
    name: "father_name",
    type: "input",
    rules: [{ required: true, message: "Father's Name Required" }],
    colSpan: 6,
  },
  {
    label: "Date of Birth",
    name: "birth_date",
    type: "input",
    inputType: "date",
    rules: [{ required: true, message: "Date of Birth Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
//From Field
export const BuilderVerFormConfig = [
  {
    label: "Name of the Builder",
    name: "builder_name",
    type: "input",
    rules: [{ required: true, message: "Name of the Builder Required" }],
    colSpan: 6,
  },
  {
    label: "Office Address of Builder",
    name: "builder_address",
    type: "input",
    rules: [{ required: true, message: "Office Address of Builder Required" }],
    colSpan: 6,
  },
  {
    label: "Site Address",
    name: "site_address",
    type: "input",
    rules: [{ required: true, message: "Site Address Required" }],
    colSpan: 6,
  },
  {
    label: "Contact Number",
    name: "contact_number",
    type: "number-input",
    inputType: "text",
    rules: [
      {
        pattern: /^\d{0,10}$/,
        message: "Valid Contact Number (10 digits) Required",
      },
    ],
    colSpan: 6,
    maxLength: 10,
  },
  {
    label: "Contact Person Name",
    name: "contact_person_name",
    type: "input",
    rules: [{ required: true, message: "Contact Person Name Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
//From Field
export const BRTVerificationFormConfig = [
  { label: "Project ID", name: "project_id", type: "text", colSpan: 4 },
  {
    label: "Project Name",
    name: "project_name",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "Project Name Required" },
  },
  {
    label: "Name of Builder",
    name: "builder_name",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "Name of Builder Required" },
  },
  {
    label: "Person Name",
    name: "person_name",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "Person Name Required" },
  },
  {
    label: "Person Number",
    name: "person_number",
    type: "number-input",
    colSpan: 4,
    rules: [
      {
        pattern: /^\d{0,10}$/,
        message: "Valid Contact Number (10 digits) Required",
      },
    ],
    maxLength: 10,
  },
  {
    label: "Person RERA ID",
    name: "rera_id",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "Person RERA ID Required" },
  },
  {
    label: "Address",
    name: "address",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "Address Required" },
  },
  {
    label: "City",
    name: "city",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "City Required" },
  },
  {
    label: "Pincode",
    name: "pincode",
    type: "text",
    colSpan: 4,
    validation: { pattern: /^\d{0,6}$/, message: "Valid Pincode Required" },
  },
  {
    label: "State",
    name: "state",
    type: "text",
    colSpan: 4,
    validation: { required: true, message: "State Required" },
  },
  {
    label: "No. of Phases",
    name: "no_of_phases",
    type: "number",
    colSpan: 4,
    validation: { required: true, message: "Number of Phases Required" },
  },
  {
    label: "No. of Buildings",
    name: "no_of_building",
    type: "number",
    colSpan: 4,
    validation: { required: true, message: "Number of Buildings Required" },
  },
  {
    label: "No. of Dwelling Each Building",
    name: "no_of_dwelling_each_building",
    type: "number",
    colSpan: 4,
    validation: {
      required: true,
      message: "Number of Dwellings per Building Required",
    },
  },
  {
    label: "No. of Floors",
    name: "no_of_floors",
    type: "number",
    colSpan: 4,
    validation: { required: true, message: "Number of Floors Required" },
  },
  {
    label: "No. of Dwelling Entire Project",
    name: "no_of_dwelling_entire_project",
    type: "number",
    colSpan: 4,
    validation: {
      required: true,
      message: "Number of Dwellings Entire Project Required",
    },
  },
  {
    label: "Date of Construction Completion",
    name: "date_of_const_completion",
    type: "date",
    colSpan: 6,
    validation: { required: true, message: "Date of Completion Required" },
  },
  ...commonObjects,
];
//From Field
export const PoolPurchaseVerFormConfig = [
  {
    label: "Old Lan no.",
    name: "old_lan_no",
    type: "input",
    rules: [{ required: true, message: "Old Lan No. Required" }],
    colSpan: 6,
  },
  {
    label: "New Live Lan",
    name: "live_lan_no",
    type: "input",
    rules: [{ required: true, message: "Live Lan No. Required" }],
    colSpan: 6,
  },
  {
    label: "Customer Name",
    name: "customer_name",
    type: "input",
    rules: [{ required: true, message: "Customer Name Required" }],
    colSpan: 6,
  },
  {
    label: "Co Applicant 1 Name",
    name: "co_applicant_1",
    type: "input",
    colSpan: 6,
  },
  {
    label: "Co Applicant 2 Name",
    name: "co_applicant_2",
    type: "input",
    rules: [],
    colSpan: 6,
  },
  {
    label: "LATEST POS",
    name: "latest_pos",
    type: "input",
    rules: [{ required: true, message: "LATEST POS Required" }],
    colSpan: 6,
  },
  {
    label: "Location of Property",
    name: "location_property",
    type: "input",
    rules: [{ required: true, message: "Location Of Property Required" }],
    colSpan: 6,
  },
  {
    label: "City",
    name: "city",
    type: "input",
    rules: [{ required: true, message: "City Required" }],
    colSpan: 6,
  },
  {
    label: "State",
    name: "state",
    type: "input",
    rules: [{ required: true, message: "State Required" }],
    colSpan: 6,
  },
  {
    label: "Type",
    name: "type",
    type: "input",
    inputType: "number",
    rules: [{ required: true, message: "Type Required" }],
    colSpan: 6,
  },
  {
    label: "File",
    name: "file",
    type: "input",
    rules: [{ required: true, message: "File Required" }],
    colSpan: 6,
  },
  ...commonObjects,
];
