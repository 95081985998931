import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData,setUserData] = useState(null);
  
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user_data'));
    const storedToken = localStorage.getItem('token');
    let userRole;
    if(storedUser)
      userRole = storedUser.role;
    if (storedUser && storedToken) {
      setIsAuthenticated(true);
      setUserData(storedUser);
    }
  }, []);
  
  return (
    <AuthContext.Provider value={{ isAuthenticated, userData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;