import React, { useEffect, useState } from "react";

import api from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { AgCharts } from "ag-charts-react"; //for a graph-modal
import { API_URL, Role } from "../../utils/constant/Constant";

function Dashboard() {
  const [dashboardData, setdashboardData] = useState([]);
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const navigate = useNavigate();

  //graph-modal
  const [graph, setGraph] = useState({
    data: [
      { Date: "8/5/24", avgTemp: 2.3, Sales: 10 },
      { Date: "9/5/24", avgTemp: 2.5, Sales: 25 },
      { Date: "10/5/24", avgTemp: 3.3, Sales: 30 },
      { Date: "11/5/24", avgTemp: 6.2, Sales: 60 },
      { Date: "12/5/24", avgTemp: 4.2, Sales: 45 },
      { Date: "5/16/24", avgTemp: 7.2, Sales: 80 },
      { Date: "6/16/24", avgTemp: 2.8, Sales: 20 },
    ],
    series: [
      {
        type: "bar",
        xKey: "Date",
        yKey: "Sales",
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
      },
      {
        type: "number",
        position: "left",
      },
    ],
    legend: {
      position: "top",
    },
    title: {
      text: "Monthly  Sales",
    },
  });

  const RoleBaseNavigator = (url) => {
    if (userData.role === Role.SUPER_ADMIN) {
      navigate("/super-admin/" + url);
    } else if (
      userData.role === Role.BANKS ||
      userData.role === Role.MASTER_VENDOR
    ) {
      navigate("/admin/" + url);
    } else if (
      userData.role === Role.BRANCH_MANAGER ||
      userData.role === Role.TEAM_LEAD ||
      userData.role === Role.BACK_OFFICE
    ) {
      navigate("/user/" + url);
    } else {
      navigate("/login");
    }
  };
  //Get the case list
  const getdashbordList = async () => {
    try {
      setdashboardData([]);
      const body = {
        user_id: userData.id,
        master_id: userData.id,
      };
      const response = await api.post(API_URL.AUTH_DASHBOARD, body, token);
      if (response.status === 200) {
        setdashboardData(response.result);
      } else {
        console.error("Failed to fetch Dashboard list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getdashbordList();
  }, []);
  const userCount = dashboardData.user_count || 0;
  const branchCount = dashboardData.branch_count || 0;
  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <section id="dashboard">
            {/* <div class="dashboard-subbox"> */}
            <div className="row">
              {/* {userData.role === Role.SUPER_ADMIN && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a
                          className="card op-light-purple"
                          onClick={() => {
                            RoleBaseNavigator("usermanagement");
                          }}
                        >
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-1">Number of Users</h6>
                              <h3 className="">{dashboardData.pending_case}</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )} */}
              {/* {(userData.role === Role.BACK_OFFICE || userData.role === Role.SUPER_ADMIN) && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a
                          className="card op-light-purple"
                          onClick={() => {
                            RoleBaseNavigator("pickedcases");
                          }}
                        >
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-1">Pending Cases</h6>
                              <h3 className="">{dashboardData.pending_case}</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                    {(userData.role === Role.BACK_OFFICE || userData.role === Role.BANKS) && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a
                          className="card op-light-orange"
                          onClick={() => {
                            RoleBaseNavigator("casemanagement");
                          }}
                        >
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-2">Pending Points</h6>
                              <h3 className="">
                                {dashboardData.pending_points}
                              </h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                    {(userData.role === Role.BACK_OFFICE || userData.role === Role.BANKS) && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a className="card op-smalt-blue">
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-3">TAT+</h6>
                              <h3 className="">0</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )} */}
              {[
                Role.MASTER_VENDOR,
                Role.BRANCH_MANAGER,
                Role.TEAM_LEAD,
                Role.BACK_OFFICE,
              ].includes(userData.role) && (
                <>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">My Branches</h6>
                          <h3 className="">{branchCount}</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Total Clients</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Total User</h6>
                          <h3>{userCount}</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Target of month</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Target MTD</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Revenue Target</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Revenue MTD</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">
                            Revenue Achievement in %
                          </h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Shortfall</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xxl-3">
                    <a className="card op-warm-blue">
                      <div className="card-body card-img">
                        <div className="icon">
                          <svg
                            width={51}
                            height={51}
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="23.2988"
                              cy="23.2988"
                              r="24.2988"
                              transform="matrix(1 0 0 -1 2 49)"
                              fill="#E7E7E7"
                              stroke="#5A639C"
                              strokeWidth={2}
                            />
                            <g clipPath="url(#clip0_347_57)">
                              <path
                                d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                fill="#5A639C"
                              />
                              <path
                                d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                fill="#5A639C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_347_57">
                                <rect
                                  width={25}
                                  height={25}
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-box">
                          <h6 className="card-title-4">Projected Expenses</h6>
                          <h3 className="">0</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              )}
              {/* {userData.role === Role.BACK_OFFICE && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a className="card op-jungle-green">
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-5">Received Today</h6>
                              <h3 className="">0</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                    {userData.role === Role.BACK_OFFICE && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a
                          className="card op-bluish"
                          onClick={() => {
                            RoleBaseNavigator("clientbranchmanagement");
                          }}
                        >
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-6">Total Branches</h6>
                              <h3 className="">{dashboardData.branch_count}</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                    {(userData.role === Role.BACK_OFFICE ||
                      userData.role === Role.BRANCH_MANAGER ||
                      userData.role === Role.TEAM_LEAD) && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a className="card op-lovely-purple">
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#5A639C"
                                  strokeWidth={2}
                                />
                                <g clipPath="url(#clip0_347_57)">
                                  <path
                                    d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                    fill="#5A639C"
                                  />
                                  <path
                                    d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                    fill="#5A639C"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_347_57">
                                    <rect
                                      width={25}
                                      height={25}
                                      fill="white"
                                      transform="translate(13 13)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-7">Completed Today</h6>
                              <h3 className="">0</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                    {(userData.role === Role.BACK_OFFICE || userData.role === Role.TEAM_LEAD) && (
                      <div className="col-md-6 col-lg-4 col-xxl-3">
                        <a className="card op-reef-gold">
                          <div className="card-body card-img">
                            <div className="icon">
                              <svg
                                width={51}
                                height={51}
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="23.2988"
                                  cy="23.2988"
                                  r="24.2988"
                                  transform="matrix(1 0 0 -1 2 49)"
                                  fill="#E7E7E7"
                                  stroke="#DD761C"
                                  strokeWidth={2}
                                />
                                <path
                                  d="M26.2844 15.5713C25.5796 15.5722 24.8766 15.6425 24.1855 15.7811C24.5556 16.9503 24.5976 18.1987 24.3069 19.3901C24.0161 20.5816 23.4038 21.6703 22.5366 22.5375C21.6695 23.4047 20.5809 24.0171 19.3896 24.3078C18.1983 24.5986 16.95 24.5566 15.7809 24.1865C15.3481 26.3529 15.5955 28.6001 16.4893 30.6205C17.383 32.6408 18.8794 34.3354 20.7735 35.4723C22.6676 36.6092 24.8668 37.1327 27.0699 36.9712C29.273 36.8096 31.3724 35.971 33.0804 34.57C34.7885 33.169 36.0217 31.2742 36.6113 29.1452C37.2009 27.0161 37.118 24.7567 36.3739 22.6766C35.6298 20.5965 34.261 18.7972 32.4548 17.5253C30.6486 16.2533 28.4935 15.5708 26.2844 15.5713ZM31.6411 27.1784H26.2844C26.0476 27.1784 25.8206 27.0844 25.6531 26.9169C25.4857 26.7495 25.3916 26.5224 25.3916 26.2856V20.9284C25.3916 20.6916 25.4857 20.4645 25.6531 20.2971C25.8206 20.1296 26.0476 20.0356 26.2844 20.0356C26.5212 20.0356 26.7483 20.1296 26.9157 20.2971C27.0831 20.4645 27.1772 20.6916 27.1772 20.9284V25.3927H31.6411C31.8779 25.3927 32.1049 25.4868 32.2724 25.6542C32.4398 25.8217 32.5339 26.0488 32.5339 26.2856C32.5339 26.5224 32.4398 26.7495 32.2724 26.9169C32.1049 27.0844 31.8779 27.1784 31.6411 27.1784Z"
                                  fill="#DD761C"
                                />
                                <path
                                  d="M23.6061 17.8036C23.6061 16.6557 23.2658 15.5337 22.6281 14.5793C21.9905 13.6249 21.0842 12.881 20.0238 12.4418C18.9634 12.0025 17.7966 11.8876 16.6709 12.1115C15.5453 12.3354 14.5113 12.8882 13.6997 13.6998C12.8881 14.5115 12.3354 15.5456 12.1115 16.6714C11.8876 17.7971 12.0025 18.964 12.4417 20.0245C12.881 21.085 13.6247 21.9914 14.5791 22.6291C15.5334 23.2668 16.6553 23.6071 17.8031 23.6071C19.3416 23.6053 20.8165 22.9932 21.9044 21.9052C22.9922 20.8173 23.6042 19.3422 23.6061 17.8036ZM16.9103 15.5714C16.9103 15.3346 17.0043 15.1075 17.1718 14.9401C17.3392 14.7726 17.5663 14.6786 17.8031 14.6786C18.0398 14.6786 18.2669 14.7726 18.4344 14.9401C18.6018 15.1075 18.6958 15.3346 18.6958 15.5714V17.3571C18.6958 17.5939 18.6018 17.821 18.4344 17.9885C18.2669 18.1559 18.0398 18.25 17.8031 18.25C17.5663 18.25 17.3392 18.1559 17.1718 17.9885C17.0043 17.821 16.9103 17.5939 16.9103 17.3571V15.5714ZM16.9103 20.0357C16.9103 19.8591 16.9626 19.6865 17.0607 19.5397C17.1588 19.3928 17.2983 19.2784 17.4614 19.2108C17.6245 19.1432 17.8041 19.1256 17.9772 19.16C18.1504 19.1945 18.3095 19.2795 18.4344 19.4044C18.5592 19.5292 18.6442 19.6883 18.6787 19.8615C18.7131 20.0347 18.6955 20.2142 18.6279 20.3774C18.5603 20.5405 18.4459 20.68 18.2991 20.7781C18.1523 20.8762 17.9796 20.9286 17.8031 20.9286C17.5663 20.9286 17.3392 20.8345 17.1718 20.6671C17.0043 20.4996 16.9103 20.2725 16.9103 20.0357Z"
                                  fill="#DD761C"
                                />
                              </svg>
                            </div>
                            <div className="text-box">
                              <h6 className="card-title-8">Completed Points</h6>
                              <h3 className="">0</h3>
                            </div>
                          </div>
                        </a>
                      </div>
                    )} */}
            </div>
            {/* garph-modal */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "50px",
              }}
            >
              <div style={{ height: "325px", width: "48%" }}>
                <AgCharts options={graph} />
              </div>
              <div style={{ height: "325px", width: "48%" }}>
                <AgCharts options={graph} />
              </div>
            </div>

            {/* </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
