import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdatePincode,
  fetchPerticularPincodes,
} from "../../redux/slices/pincodeSlice";

function AddNewPincode() {
  const dispatch = useDispatch();
  const { selectedPincode } = useSelector((state) => state.pincode);

  const userData = JSON.parse(localStorage.getItem("user_data"));
  const location = useLocation();
  const navigate = useNavigate();

  const [pincodeId, setPincodeId] = useState(
    location.state?.pincode_id || null
  );
  const [initialValue, setInitialValue] = useState(null);

  const clear = () => {
    setInitialValue(null);
  };

  const onCancelButtonClick = () => {
    navigate(-1);
    clear();
  };

  useEffect(() => {
    if (pincodeId) {
      const body = {
        pincode_id: pincodeId,
      };
      console.log("fetching the pincode");
      dispatch(fetchPerticularPincodes(body));
    }
  }, [pincodeId]);
  useEffect(() => {
    console.log("the pincode ", selectedPincode);
    if (selectedPincode) {
      console.log("the pincode ", selectedPincode);
      setInitialValue({
        pincodeId: pincodeId,
        pincode: selectedPincode.pincode,
        state: selectedPincode.state_name,
        city: selectedPincode.district,
      });
    }
  }, [selectedPincode]);

  const createPincode = async (values) => {
    const body = {
      user_id: userData.id,
      pincode: values.pincode,
      district: values.city,
      state_name: values.state,
    };
    if (pincodeId) {
      body.pincode_id = pincodeId;
    }
    const isUpdate = pincodeId ? true : false;
    dispatch(createOrUpdatePincode({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        navigate("/super-admin/pincode");
      })
      .catch((error) => {});
  };

  const formConfig = [
    {
      label: "Pincode",
      name: "pincode",
      type: "input",
      rules: [
        {
          required: true,
          message: "Please Enter 6 Digit Number",
          pattern: /^[0-9]{6}$/,
        },
      ],
      colSpan: 8,
    },
    {
      label: "City",
      name: "city",
      type: "input",
      rules: [{ required: true, message: "Enter City" }],
      colSpan: 8,
    },
    {
      label: "State",
      name: "state",
      type: "input",
      rules: [{ required: true, message: "Enter State" }],
      colSpan: 8,
    },
    {
      label: "paymentId",
      name: "paymentId",
      type: "hidden",
      initialValue: pincodeId,
    },
  ];

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Pincode Management</h5>
                </div>
              </div>
            </div>
          </div>
          <section id="client-management-form">
            <div className="add_user-box">
              <div className="add_user-form">
                <DynamicForm
                  config={formConfig}
                  onFinish={createPincode}
                  initialValues={initialValue}
                  onCancel={onCancelButtonClick}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AddNewPincode;
