import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "bootstrap";
import { Select, Switch } from "antd";
import { Role } from "../../utils/constant/Constant";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AntdTable from "../../components/AntdTable/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientBranch,
  fetchAllClientBranches,
  resetClientBranchState,
  updateClientBranchStatus,
} from "../../redux/slices/clientBranchSlice";

function ClientBranchManagement() {
  const dispatch = useDispatch();
  const { clientBranches, total } = useSelector((state) => state.clientBranch);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(resetClientBranchState());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllClientBranches(body));
  };

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user_data"));

  //Update branch Status
  const handleClientBranchStatusUpdate = async (branch) => {
    let status = branch.status == 1 ? 0 : 1;
    const body = {
      branch_id: branch.id,
      status: status,
    };
    dispatch(updateClientBranchStatus(body))
      .unwrap()
      .then(() => {
        fetchData();
      })
      .catch((err) => {});
  };

  const addBranch = () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/clientbranch/add");
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/clientbranch/add");
    } else {
      navigate("/user/clientbranch/add");
    }
  };

  // Update the client list
  const updateBranch = async (branch) => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/clientbranch/add", {
        state: { clientBranchId: branch.id },
      });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/clientbranch/add", {
        state: { clientBranchId: branch.id },
      });
    } else {
      navigate("/user/clientbranch/add", {
        state: { clientBranchId: branch.id },
      });
    }
  };

  //Delete Branch
  const handleDeleteClientBranch = async (branch) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete client branch?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { branch_id: branch.id };
        dispatch(deleteClientBranch(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => {});
      }
    });
  };

  const columns = [
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    {
      title: "Name",
      dataIndex: "branch_name",
      key: "branch_name",
      className: "dt-left",

      sortable: true, // Enable sort
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      className: "dt-center",

      sortable: true, // Enable sort
    },
    {
      title: "Code",
      dataIndex: "branch_code",
      key: "branch_code",
      className: "dt-center",
      sortable: true, // Enable sort
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      className: "dt-center",
      sortable: true, // Enable sort
    },
    {
      title: "TAT",
      dataIndex: "tat",
      key: "tat",
      render: (tat, record) => (
        <>
          {tat} {record.tat_type == 1 ? "Day" : "Hour"}
        </>
      ),
      className: "dt-center",
      sortable: true, // Enable sort
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch
          checked={status == 1}
          onChange={() => handleClientBranchStatusUpdate(record)} // Function to handle status change
        />
      ),
      className: "dt-center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => updateBranch(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteClientBranch(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    <h5>Client Branch Management</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="button-box">
                    <ul className="">
                      <li>
                        <button className="blue-btn" onClick={addBranch}>
                          + Add New
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <section id="branch-management">
              <AntdTable
                dataSource={clientBranches}
                columns={columns}
                pageNo={pageNo}
                pageSize={pageSize}
                setPageNo={setPageNo}
                setPageSize={setPageSize}
                total={total}
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientBranchManagement;
