import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoSm from "../../assets/images/logo-sm.png";
import { Role } from "../../utils/constant/Constant";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  BranchManagementIcon,
  CaseDetailIcon,
  ClientManagementIcon,
  DashboardIcon,
  HolidayManagementIcon,
  PaymentManagementIcon,
  PickedIcon,
  SettingIcon,
  UserManagementIcon,
} from "./SidebarIcons";

function Sidebar({ collapsed }) {
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (levelKeys[latestOpenKey] === 1) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user_data"))
  );
  const [items, setItems] = useState([]);
  const [levelKeys, setLevelKeys] = useState({});
  const [isactive, setIsactive] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [current, setCurrent] = useState();
  const [url, setUrl] = useState("");
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Define items based on userData.role
    const newItems = [
      ...(Role.SUPER_ADMIN === userData.role ||
      Role.BANKS === userData.role ||
      Role.MASTER_VENDOR === userData.role ||
      Role.BRANCH_MANAGER === userData.role ||
      Role.TEAM_LEAD === userData.role ||
      Role.BACK_OFFICE === userData.role
        ? [
            {
              key: "Dashboard",
              label: <span className="label-margin">Dashboard</span>,
              icon: <DashboardIcon />,
            },
          ]
        : []),
      ...(Role.SUPER_ADMIN === userData.role ||
      Role.BANKS === userData.role ||
      Role.BRANCH_MANAGER === userData.role ||
      Role.MASTER_VENDOR === userData.role ||
      Role.TEAM_LEAD === userData.role
        ? [
            {
              key: "UserManagement",
              label: (
                <span className="label-margin">
                  {userData.role === Role.SUPER_ADMIN ? "Vendors " : "Users "}
                </span>
              ),
              icon: <UserManagementIcon />,
            },
          ]
        : []),
      // ...(Role.BRANCH_MANAGER === userData.role
      //   ? [
      //       {
      //         key: "CaseDetails",
      //         label: <span className="label-margin">Case Deatil</span>,
      //         icon: <CaseDetailIcon />,
      //       },
      //     ]
      //   : []),
      ...(Role.BANKS === userData.role ||
      Role.TEAM_LEAD === userData.role ||
      Role.BACK_OFFICE === userData.role
        ? [
            {
              key: "CaseManagement",
              label: <span className="label-margin">Cases </span>,
              icon: <CaseDetailIcon />,
            },
          ]
        : []),
      ...(Role.MASTER_VENDOR === userData.role ||
      Role.BRANCH_MANAGER === userData.role
        ? [
            {
              key: "Client",
              label: <span className="label-margin">Client</span>,
              icon: <ClientManagementIcon />,
              children: [
                userData.role !== Role.BRANCH_MANAGER && {
                  key: "ClientManagement",
                  label: (
                    <span className="label-margin">Client </span>
                  ),
                },
                {
                  key: "ClientBranchManagement",
                  label: (
                    <span className="label-margin">Client Branch </span>
                  ),
                },
                {
                  key: "DocConfig",
                  label: <span className="label-margin">Document Config</span>,
                },
                {
                  key: "Category",
                  label: <span className="label-margin">Category</span>,
                },
              ],
            },
          ]
        : []),
      ...(Role.MASTER_VENDOR === userData.role ||
      Role.BRANCH_MANAGER === userData.role
        ? [
            {
              key: "BranchManagement",
              label: (
                <span className="label-margin">
                  {userData.role === Role.MASTER_VENDOR
                    ? "Branch "
                    : "My Branches"}
                </span>
              ),
              icon: <BranchManagementIcon />,
            },
          ]
        : []),
      ...(Role.BRANCH_MANAGER === userData.role
        ? [
            {
              key: "HolidayManagement",
              label: <span className="label-margin">Holiday </span>,
              icon: <HolidayManagementIcon />,
            },
          ]
        : []),
      ...(Role.TEAM_LEAD === userData.role || Role.BACK_OFFICE === userData.role
        ? [
            {
              key: "PickedCases",
              label: <span className="label-margin">Picked Case</span>,
              icon: <PickedIcon />,
            },
          ]
        : []),
      ...(Role.MASTER_VENDOR === userData.role ||
      Role.BRANCH_MANAGER === userData.role
        ? [
            {
              key: "PaymentManagement",
              label: <span className="label-margin">Payment </span>,
              icon: <PaymentManagementIcon />,
            },
          ]
        : []),
      ...(Role.MASTER_VENDOR === userData.role
        ? [
            {
              key: "Target",
              label: <span className="label-margin">Target</span>,
              icon: <PaymentManagementIcon />,
              children: [
                {
                  key: "NewTarget",
                  label: <span className="label-margin">New Target</span>,
                },
                {
                  key: "TargetHistory",
                  label: <span className="label-margin">Target History</span>,
                },
              ],
            },
          ]
        : []),
      ...(Role.SUPER_ADMIN === userData.role
        ? [
            {
              key: "PincodeManagement",
              label: <span className="label-margin">Pincode</span>,
              icon: <UserManagementIcon />,
            },
          ]
        : []),

      ...(Role.SUPER_ADMIN === userData.role
        ? [
            {
              key: "Settings",
              label: <span className="label-margin">Settings</span>,
              icon: <SettingIcon />,
              children: [
                {
                  key: "Field",
                  label: <span className="label-margin">Field</span>,
                },
                {
                  key: "PrivacyPolicy",
                  label: <span className="label-margin">Privacy Policy</span>,
                },
                {
                  key: "Term&Conditions",
                  label: (
                    <span className="label-margin">Terms & Conditions</span>
                  ),
                },
              ],
            },
          ]
        : []),
    ];

    setItems(newItems);
    setLevelKeys(getLevelKeys(newItems));
  }, [userData]);

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleClick = (e) => {
    if (e.keyPath.length === 1) onOpenChange([e.key]);
    setCurrent(e);

    let targetPath = "";

    switch (userData.role) {
      case Role.SUPER_ADMIN:
        targetPath = "/super-admin/dashboard";
        break;
      case Role.MASTER_VENDOR:
      case Role.BANKS:
        targetPath = "/admin/dashboard";
        break;
      case Role.BRANCH_MANAGER:
      case Role.TEAM_LEAD:
      case Role.BACK_OFFICE:
      case Role.FE_PE:
        targetPath = "/user/dashboard";
        break;
      default:
        break;
    }

    if (e.key === "UserManagement") {
      switch (userData.role) {
        case Role.SUPER_ADMIN:
          targetPath = "/super-admin/user";
          break;
        case Role.MASTER_VENDOR:
        case Role.BANKS:
          targetPath = "/admin/user";
          break;
        case Role.BRANCH_MANAGER:
        case Role.TEAM_LEAD:
        case Role.BACK_OFFICE:
        case Role.FE_PE:
          targetPath = "/user/user";
          break;
        default:
          break;
      }
    }

    if (e.key === "CaseDetails") {
      switch (userData.role) {
        case Role.BRANCH_MANAGER:
          targetPath = "/user/casedetails";
          break;
        default:
          break;
      }
    }

    if (e.key === "CaseManagement") {
      switch (userData.role) {
        case Role.BANKS:
          targetPath = "/admin/case";
          break;
        case Role.BACK_OFFICE:
        case Role.TEAM_LEAD:
          targetPath = "/user/case";
          break;
        default:
          break;
      }
    }

    if (e.key === "ClientBranchManagement") {
      switch (userData.role) {
        case Role.MASTER_VENDOR:
          targetPath = "/admin/clientbranch";
          break;
        case Role.BRANCH_MANAGER:
          targetPath = "/user/clientbranch";
          break;
        default:
          break;
      }
    }

    if (e.key === "ClientManagement") {
      switch (userData.role) {
        case Role.MASTER_VENDOR:
          targetPath = "/admin/client";
          break;
        default:
          break;
      }
    }

    if (e.key === "BranchManagement") {
      switch (userData.role) {
        case Role.MASTER_VENDOR:
          targetPath = "/admin/branch";
          break;
        case Role.BRANCH_MANAGER:
          targetPath = "/user/branch";
          break;
        default:
          break;
      }
    }

    if (e.key === "HolidayManagement") {
      switch (userData.role) {
        case Role.BRANCH_MANAGER:
          targetPath = "/user/holiday";
          break;
        default:
          break;
      }
    }

    if (e.key === "DocConfig") {
      switch (userData.role) {
        case Role.MASTER_VENDOR:
          targetPath = "/admin/documentconfiguration";
          break;
        case Role.BRANCH_MANAGER:
          targetPath = "/user/documentconfiguration";
          break;
        default:
          break;
      }
    }
    if (e.key === "Category") {
      switch (userData.role) {
        case Role.MASTER_VENDOR:
          targetPath = "/admin/categoryManagement";
          break;
        case Role.BRANCH_MANAGER:
          targetPath = "/user/categoryManagement";
          break;
        default:
          break;
      }
    }
    if (e.key === "Settings") {
      targetPath = "/super-admin/setting";
    }
    if (e.key === "Field") {
      targetPath = "/super-admin/setting/field";
    }
    if (e.key === "PrivacyPolicy") {
      targetPath = "/super-admin/setting/privacypolicy";
    }

    if (e.key === "Term&Conditions") {
      targetPath = "/super-admin/setting/term&conditions";
    }

    if (e.key === "PickedCases") {
      switch (userData.role) {
        case Role.TEAM_LEAD:
          targetPath = "/user/pickedcases";
          break;
        case Role.BACK_OFFICE:
          targetPath = "/user/pickedcases";
          break;
        default:
          break;
      }
    }

    if (e.key === "PaymentManagement") {
      switch (userData.role) {
        case Role.MASTER_VENDOR:
          targetPath = "/admin/payment";
          break;

        case Role.BRANCH_MANAGER:
          targetPath = "/user/payment";
          break;
        default:
          break;
      }
    }
    if (e.key === "PincodeManagement") {
      switch (userData.role) {
        case Role.SUPER_ADMIN:
          targetPath = "/super-admin/pincode";
          break;
        default:
          break;
      }
    }

    if (e.key === "Target") {
      targetPath = "/admin/target";
    }

    if (e.key === "NewTarget") {
      targetPath = "/admin/target/add";
    }

    if (e.key === "TargetHistory") {
      targetPath = "/admin/target/history";
    }
    // if (e.key === "PaymentManagement") {
    //   switch (userData.role) {
    //     case Role.MASTER_VENDOR:
    //       targetPath = "/admin/payment";
    //       break;
    //     default:
    //       break;
    //   }
    // }

    setUrl(targetPath);
    navigate(targetPath);
  };

  return (
    <>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="main-navbar">
          <div className="nav">
            <div className="nav-logo">
              <Link to="#" className="full-logo">
                <img src={logo} alt="logo" />
              </Link>
              <Link to="#" className="sm-logo">
                <img src={logoSm} alt="logo" />
              </Link>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <Menu
                mode="inline"
                openKeys={openKeys} // Updated to use openKeys
                onOpenChange={onOpenChange} // Updated onOpenChange handler
                // selectedKeys={[current.key]} // Ensure the current selection is highlighted
                items={items}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </Sider>
    </>
  );
}

export default Sidebar;
