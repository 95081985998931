import React, { useState, useEffect, useRef } from "react";
import api from "../../services/Api";
import { triggerConfirmation, useToast } from "../../utils/helpers/CommFun";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ExcelFilePreview from "../../components/ExcelFilePreview";
import { API_URL, Role } from "../../utils/constant/Constant";
import { Form, Button, Select, Row, Col, Tooltip, Space, Upload } from "antd";
import AntdTable from "../../components/AntdTable/Table";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteCase, fetchAllCases } from "../../redux/slices/caseSlice";

function CaseManagement() {
  const dispatch = useDispatch();
  const { clientBranches } = useSelector((state) => state.clientBranch);
  const { clients } = useSelector((state) => state.client);
  const { Cases, total } = useSelector((state) => state.Case);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  // useEffect(() => {
  //   dispatch(fetchAllClientBranches({}));
  //   dispatch(fetchAllClients({}));
  // }, [dispatch]);

  useEffect(() => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllCases(body));
  }, [dispatch, pageNo, pageSize, searchQuery]);

  //get token
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const [filterForm] = Form.useForm();
  const [filterValues, setFilterValues] = useState();
  const [excelForm] = Form.useForm();
  const [excelFormValues, setExcelFormValues] = useState();

  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pointList, setPointList] = useState([
    { id: 21, name: "BRT" },
    { id: 22, name: "Pool Purchase" },
    { id: 23, name: "Partial Disbursement" },
  ]);
  const [verificationType, setVerificationType] = useState("");
  const [selectedBranch, setSelectedBranches] = useState("");
  const [excelFileData, setExcelFileData] = useState(null);
  const previewRef = useRef(null);

  const navigate = useNavigate();

  //sweet alert
  const Toast = useToast();
  const bmId = JSON.parse(localStorage.getItem("user_data")).bm_id;

  //handle modal open & close
  const handleClose = () => {
    window.location.reload();
  };

  //Delete the case
  const handleDeleteCase = async (caseRecord, forceDelete = false) => {
    const confirmationMessage = forceDelete
      ? "Case has Applicant, Do you still want to delete?"
      : "Are you sure you want to delete this case?";

    await triggerConfirmation(
      "Delete",
      confirmationMessage,
      "warning",
      "Delete"
    ).then(async (result) => {
      // try {
      const body = {
        case_id: caseRecord.id,
        force_delete: forceDelete,
      };
      dispatch(deleteCase(body))
        .unwrap()
        .then(() => {
          const body = {
            pageNo: pageNo,
            pageSize: pageSize,
            search: searchQuery,
          };
          dispatch(fetchAllCases(body));
        })
        .catch((err) => {
          handleDeleteCase(caseRecord, true);
        });
      //   const response = await api.post(API_URL.DELETE_CASE, body, token);
      //   if (response.status === 200) {
      //     Toast.fire({
      //       icon: "success",
      //       title: response.message,
      //     });
      //     setPointList([]);
      //   } else {
      //     handleDeleteCase(caseRecord, true);
      //   }
      // } catch (error) {
      //   console.error(error);
      // } finally {
      // }
    });
  };

  const onAddCase = async () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case/addCase");
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case/addCase");
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case/addCase");
    } else {
      navigate("/login");
    }
  };
  const onEdit = async (caseId) => {
    const data = {
      case_id: caseId.id,
    };
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case/edit", { state: data });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case/edit", { state: data });
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case/edit", { state: data });
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    setFilteredList(Cases);
  }, [Cases]);

  const columns = [
    {
      title: "Sr No.",
      key: "srNo",
      render: (_, __, index) => index + 1, // Renders the row number
      className: "dt-center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "dt-left",

      sortable: true, // Enable sort
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      className: "dt-center",
      render: (branch) => (branch ? branch.branch_name : "-"), // Render branch name or "-"
      sortable: true, // Enable sort
    },
    {
      title: "Client",
      dataIndex: "branch",
      key: "client",
      className: "dt-center",
      render: (branch) =>
        branch && branch.client_id ? branch.client_id.name : "-", // Render client name or "-"
      sortable: true, // Enable sort
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "dt-center",
      render: (status) => {
        switch (status) {
          case 0:
            return "Pending";
          case 1:
            return "Complete";
          default:
            return "-";
        }
      },
      sortable: true, // Enable sort
    },
    {
      title: "Total Points",
      dataIndex: "no_of_points",
      key: "totalPoints",
      className: "dt-center",
      render: (points) => points, // Render total points or "-"
      sortable: true, // Enable sort
    },
    {
      title: "Date-Time",
      dataIndex: "created_at",
      key: "dateTime",
      className: "dt-center",
      // render: (created_at) => moment(created_at).format("DD/MM/YYYY HH:mm:ss"), // Format date-time
      sortable: true, // Enable sort
    },
    {
      title: "Action",
      key: "action",
      className: "dt-center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => onEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ color: "#D92E38", cursor: "pointer" }}
              onClick={() => handleDeleteCase(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleFileUpload = (excelFile) => {
    const file = excelFile;
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);
      const snakeCaseData = data.map((item) => {
        let transformedItem = {};
        for (let key in item) {
          if (item.hasOwnProperty(key)) {
            // Transform key to snake_case
            const snakeCaseKey = key.trim().replace(/\s+/g, "_").toLowerCase();

            // Rename specific keys as needed
            switch (snakeCaseKey) {
              case "no._of_buildings":
                transformedItem["no_of_building"] = item[key];
                break;
              case "no._of_phases":
                transformedItem["no_of_phases"] = item[key];
                break;
              case "no._of_floors":
                transformedItem["no_of_floors"] = item[key];
                break;
              case "no._of_dwelling_entire_project":
                transformedItem["no_of_dwelling_entire_project"] = item[key];
                break;
              case "no._of_dwelling_each_building":
                transformedItem["no_of_dwelling_each_building"] = item[key];
                break;
              // Add more cases for other key renames if needed
              default:
                transformedItem[snakeCaseKey] = item[key];
            }
          }
        }
        return transformedItem;
      });

      setExcelFileData(snakeCaseData);
      // setTimeout(() => {
      //   previewRef.current.click();
      // }, 100);
      // filePreviewPage();
    };
    reader.readAsBinaryString(file);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const body = {
      verification_type: excelFormValues.verificationType,
      parent_id: userData.parent_id,
      bm_id: bmId,
      user_id: userData.id,
      branch_id: excelFormValues.branch,
      jsonData: excelFileData,
    };
    const response = await api.post(
      API_URL.CREATE_CASE_FROM_EXCEL,
      body,
      token
    );
    if (response.status === 200) {
      window.location.reload();
      Toast.fire({
        icon: "success",
        title: response.message,
      });
    } else {
      Toast.fire({
        icon: "error",
        title: response.message,
      });
    }
  };

  const isVerificationTypeSelected = verificationType !== "";
  const isBranchSelected = selectedBranch !== "";

  const handleFilterChange = (changedValues) =>
    setFilterValues((prev) => {
      return { ...prev, ...changedValues };
    });
  const handleExcelChange = (changedValues) => {
    setExcelFormValues((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  useEffect(() => {
    const cases = Cases.filter((obj) => {
      const statusMatch =
        filterValues?.status == undefined || obj.status == filterValues?.status;
      const branchMatch =
        filterValues?.branch == undefined ||
        obj.branch.branch_name == filterValues?.branch;
      const clientMatch =
        filterValues?.client == undefined ||
        obj.branch.client_id.name == filterValues?.client;
      return statusMatch && branchMatch && clientMatch;
    });
    setFilteredList(cases);
  }, [filterValues]);

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    <h5 className="bottom-line">Case Management</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="button-box">
                    <ul className="">
                      <li>
                        <button onClick={onAddCase} className="blue-btn">
                          + Add New
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body row">
                <Form
                  form={excelForm}
                  name="excelForm"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: "0 auto" }}
                  onValuesChange={handleExcelChange}
                  autoComplete="off"
                >
                  <Row gutter={[16, 24]}>
                    <Col span={6}>
                      <Form.Item
                        label="Verification Type"
                        name="verificationType"
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {pointList.map((option, i) => (
                            <Select.Option key={i} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Branch" name="branch">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {clientBranches.map((option, i) => (
                            <Select.Option key={i} value={option.id}>
                              {option.branch_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Excel File"
                        name="excelFile"
                        extra={
                          <a
                            href="/favicon.ico"
                            download="favicon.ico"
                            className="mx-3 text-decoration-underline text-primary fs-6"
                          >
                            Sample File
                          </a>
                        }
                      >
                        <Upload
                          name="logo"
                          accept=".xlsx, .xls"
                          customRequest={({ file, onSuccess }) => {
                            onSuccess();
                          }}
                          disabled={
                            !excelFormValues?.verificationType ||
                            !excelFormValues?.branch
                          }
                          onChange={(e) => {
                            handleFileUpload(e.file.originFileObj);
                          }}
                          maxCount={1}
                          style={{ maxWidth: "150px" }}
                        >
                          {excelFormValues?.excelFile?.fileList.length >
                          0 ? null : (
                            <Button icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      {excelFileData && excelFileData.length > 0 ? (
                        <Button
                          type="default"
                          className="blue-btn"
                          ref={previewRef}
                          data-bs-toggle="modal"
                          data-bs-target="#excelFileDataModal"
                          classNames="mt-3"
                        >
                          Preview
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body row">
                <Form
                  form={filterForm}
                  name="filterForm"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: "0 auto" }}
                  onValuesChange={handleFilterChange}
                  autoComplete="off"
                >
                  <Row gutter={[16, 24]}>
                    <Col span={8}>
                      <Form.Item label="Client" name="client">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {clients.map((option, i) => (
                            <Select.Option key={i} value={option.name}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Branch" name="branch">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {clientBranches.map((option, i) => (
                            <Select.Option key={i} value={option.branch_name}>
                              {option.branch_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Status" name="status">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          <Select.Option key={0} value={0}>
                            Pending
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            Complete
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            <section id="case-management">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <AntdTable
                      dataSource={filteredList}
                      columns={columns}
                      pageNo={pageNo}
                      pageSize={pageSize}
                      setPageNo={setPageNo}
                      setPageSize={setPageSize}
                      total={total}
                    />
                    <div id="excel-table"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="excelFileDataModal"
        tabIndex={-1}
        aria-labelledby="excelFileDataModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered "
          style={{ maxWidth: "80%" }}
        >
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title" id="excelFileDataModalLabel">
                File Preview
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            {/* Modal Body */}
            <div className="modal-body">
              <div className="table-responsive">
                <ExcelFilePreview fileData={excelFileData} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2%",
                }}
              >
                <Button style={{ marginLeft: "2%" }} onClick={handleClose}>
                  Cancel
                </Button>
                <Button style={{ marginLeft: "2%" }} onClick={handleSubmit}>
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaseManagement;
