import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt';
import 'datatables.net-rowreorder';

function Table({
  data,
  setData,
  columns,
  onupdate,
  ondelete,
  onredirect,
  onaddPoint,
  onupdatePoint,
  ondeletePoint,
  getPointList,
  onupdatestatus,
  onhandleShow,
  onhandleShow1,
  onviewbutton,
  selectedRowId,
  setSelectedRowId,
  isReorderable = false,
  is_sr_no = 1
}) {
  const tableRef = useRef(null);
  const [currRow, setCurrRow] = useState(selectedRowId);

  useEffect(() => {
    const dt = $(tableRef.current).DataTable({
      data: data.map(row => ({ ...row })),
      columns,
      lengthMenu: [10, 25, 50, 75, 100],
      pageLength: 10,
      rowReorder: isReorderable,
      createdRow: (row, rowData) => {
        $(row).find(".edit-btn").on("click", () => onupdate(rowData));
        $(row).find(".delete-btn").on("click", () => ondelete(rowData));
        $(row).find(".update-status").on("click", () => onupdatestatus(rowData));
        $(row).find('.redirect-btn').on('click', () => onredirect(rowData));
        $(row).find('.addpoint-btn').on('click', () => onaddPoint(rowData));
        $(row).find('.delete-point-btn').on('click', () => ondeletePoint(rowData));
        $(row).find('.update-point-btn').on('click', () => onupdatePoint(rowData));
        $(row).find(".handle-show").on("click", () => onhandleShow(rowData));
        $(row).find(".handle-show1").on("click", () => onhandleShow1(rowData));
        $(row).find(".view-btn").on("click", () => onviewbutton(rowData));
      },
      rowCallback: (row, rowData, iDisplayIndex) => {
        if (getPointList != null) {
          $(row).on('click', async () => {
            await getPointList(rowData);
            setSelectedRowId(iDisplayIndex);
            // highlightRow(iDisplayIndex);
          });
        }
        if (is_sr_no === 1) {
          $('td:first', row).html(iDisplayIndex + 1);
        }
      },
      drawCallback: () => {
        $(tableRef.current).closest('div.dt-layout-cell').addClass('table-responsive rounded-table');
      },
    });
    if (isReorderable) {
      dt.on('row-reorder', function (e, diff, edit) {
        if (diff.length > 0) {
          const firstChange = diff[0];
          const newData = [...data];

          // Remove the moved row from its old position
          const movedRow = newData.splice(firstChange.oldPosition, 1)[0];
          // Insert the moved row at its new position
          newData.splice(firstChange.newPosition, 0, movedRow);

          // Update the state 'data' with the reordered newData
          setData(newData);
        }
      });
    }
    if (selectedRowId != null && selectedRowId != undefined)
      highlightRow(selectedRowId);
    return () => {
      if (dt) {
        dt.destroy();
      }
    };
  }, [data, columns, onupdate, ondelete, onredirect, getPointList, setData, onupdatePoint, ondeletePoint, onupdatestatus, onhandleShow, onhandleShow1, onviewbutton, is_sr_no, setSelectedRowId]);

  const highlightRow = (index) => {
    $(tableRef.current).find('tr').removeClass('selected');
    // setTimeout(() => {
    $(tableRef.current).find('tbody tr').eq(index).addClass('selected');
    setCurrRow(selectedRowId);
    // }, 1);
  }


  return (
    <table ref={tableRef} className='table table-hover'></table>
  );
}


export default Table;
