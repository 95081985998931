import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { Role } from "../../utils/constant/Constant";
import AntdTable from "../../components/AntdTable/Table";
import { Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  fetchAllUsers,
  updateUserStatus,
} from "../../redux/slices/userSlice";

function UserMenagement() {
  const dispatch = useDispatch();
  const { users, total } = useSelector((state) => state.user);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const userData = JSON.parse(localStorage.getItem("user_data"));
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllUsers(body));
  };

  //Delet User
  const handleDeleteUser = async (user) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { user_id: user.id };
        dispatch(deleteUser(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => {});
      }
    });
  };
  //Update User Status
  const handleUpdateUserStatus = async (user) => {
    let status = user.status == 1 ? 0 : 1;
    const body = {
      user_id: user.id,
      status: status,
    };
    dispatch(updateUserStatus(body))
      .unwrap()
      .then(() => {
        fetchData();
      })
      .catch((err) => {});
  };

  const updateUser = async (user) => {
    const data = {
      user_id: user.id,
    };
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/user/add", { state: data });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/user/add", { state: data });
    } else {
      navigate("/user/user/add", { state: data });
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text, record) =>
        `${record.first_name} ${record.middle_name ? record.middle_name : ""} ${
          record.last_name ? record.last_name : ""
        }`,
      className: "dt-left",

      sortable: true, // Enable sort
    },
    {
      title: "Mobile No.",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => text || "-",
      className: "dt-center",

      sortable: true, // Enable sort
    },
    {
      title: "Type",
      key: "role",
      render: (text, record) => {
        switch (record.role) {
          case Role.SUPER_ADMIN:
            return "Super admin";
          case Role.BANKS:
            return "Bank";
          case Role.MASTER_VENDOR:
            return "Master Vendor";
          case Role.BRANCH_MANAGER:
            return "Branch Manager";
          case Role.TEAM_LEAD:
            return "TL";
          case Role.BACK_OFFICE:
            return "Back Office";
          case Role.FE_PE:
            return "FE/PE";
          default:
            return "-";
        }
      },
      className: "dt-center",

      sortable: true, // Enable sort
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className="form-check form-switch d-flex justify-content-center">
          <Switch
            checked={text == 1}
            onChange={() => handleUpdateUserStatus(record)} // Handle switch change
          />
        </div>
      ),
      className: "dt-center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => updateUser(record)} // Implement handleEdit function
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteUser(record)} // Implement handleDelete function
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>User Management</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="button-box">
                  <ul className="">
                    {[
                      Role.SUPER_ADMIN,
                      Role.BANKS,
                      Role.MASTER_VENDOR,
                      Role.BRANCH_MANAGER,
                      Role.TEAM_LEAD,
                    ].some((role) => userData.role == role) && (
                      <li>
                        <Link
                          to={
                            [Role.SUPER_ADMIN].some(
                              (role) => userData.role == role
                            )
                              ? "/super-admin/user/add"
                              : [Role.BANKS, Role.MASTER_VENDOR].some(
                                  (role) => userData.role == role
                                )
                              ? "/admin/user/add"
                              : "/user/user/add"
                          }
                          className="blue-btn"
                        >
                          + Add New
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section id="new-user">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <AntdTable
                    dataSource={users}
                    columns={columns}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    setPageNo={setPageNo}
                    setPageSize={setPageSize}
                    total={total}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default UserMenagement;
