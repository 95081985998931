import React, { useState, useEffect } from "react";
import Header from "../../components/layouts/Header";
import Sidebar from "../../components/layouts/Sidebar";
import eyeScan from "../../assets/images/eye-scan.png";
import lightGreen from "../../assets/images/light-green.png";
import api from "../../services/Api";
import { API_URL } from "../../utils/constant/Constant";

function CaseDetails() {
  const [caseList, setCaseList] = useState([]);
  const [loading, setLoading] = useState(false);

  //get token
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user_data"));

  //Get the case detail
  const getCaseDetail = async () => {
    try {
      setLoading(true);
      setCaseList([]);
      const body = {
        bm_id: parseInt(userData.id),
      };
      const response = await api.post(API_URL.GET_CASE_DETAILS, body, token);
      if (response.status === 200) {
        setCaseList(response.result);
      } else {
        console.error("Failed to fetch client list");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCaseDetail();
  }, []);

  return (
   <>
          <div className="main-contant">
            <div className="container-fluid">
              <div className="contant-box">
                <div className="sub-header">
                  <div className="row align-items-end">
                    <div className="col-8">
                      <div className="left">
                        <div className="vendor-branch-box">
                          {/* <div className="vendor text">
                            <label>Vendor Branch:</label>
                            <span>Mumbai Crux</span>
                          </div>
                          <div className="b/o text">
                            <label>B/O:</label>
                            <span>Ravindra Soni</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="button-box report-btn-box">
                        <p>Report:</p>
                        <ul className="">
                          <li>
                            <a href="#">
                              <img src={eyeScan} />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img
                                src="./assets/images/download-report-btn.png"
                                alt=""
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <section id="case-detail">
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive rounded-table">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th />
                              <th>Sr No.</th>
                              <th>Name</th>
                              <th>Branch</th>
                              <th>Client</th>
                              <th>Status</th>
                              <th>Total Points</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {caseList.map((caseItem, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <button
                                      className="btn btn-default table-toggle-btn"
                                      data-bs-toggle="collapse"
                                      href={`#multiCollapseExample${index}`}
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls={`multiCollapseExample${index}`}
                                    >
                                      <i className="fa-solid fa-caret-right" />
                                    </button>
                                  </td>
                                  <td>{caseItem.id}</td>
                                  <td>{caseItem.case_id.name}</td>
                                  <td>
                                    {caseItem.case_id?.branch?.branch_name ??
                                      ""}
                                  </td>
                                  <td>{caseItem.type}</td>
                                  <td>
                                    {caseItem.case_id.status === 1
                                      ? "success"
                                      : "rejected"}
                                  </td>
                                  <td>{caseItem.case_id.no_of_points}</td>
                                  <td>
                                    {new Date(
                                      caseItem.case_id.received_at
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                                <tr
                                  className="collapse"
                                  id={`multiCollapseExample${index}`}
                                >
                                  <td />
                                  <td>{caseItem.detailId}</td>
                                  <td>{caseItem.name}</td>
                                  <td>
                                    {caseItem.case_id?.branch?.branch_name ??
                                      ""}
                                  </td>
                                  <td>{caseItem.detailType}</td>
                                  <td>
                                    <span
                                      className={
                                        caseItem.detailStatus === "Rejected"
                                          ? "rejected"
                                          : "success"
                                      }
                                    >
                                      {caseItem.detailStatus}
                                    </span>
                                  </td>
                                  <td>{caseItem.detailDate}</td>
                                  <td>
                                    <i className="fa-solid fa-eye" />
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
  </>
  );
}

export default CaseDetails;
