import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "bootstrap";
import { Role, STATUS_TYPE } from "../../utils/constant/Constant";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import AntdTable from "../../components/AntdTable/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVendorBranch,
  fetchAllVendorBranches,
  resetVendorBranchState,
  updateVendorBranchStatus,
} from "../../redux/slices/vendorBranchSlice";

function BranchManagement() {
  const dispatch = useDispatch();
  const { vendorBranches, status, total } = useSelector(
    (state) => state.vendorBranch
  );
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllVendorBranches(body));
  };

  
  useEffect(() => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllVendorBranches(body));
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const userData = JSON.parse(localStorage.getItem("user_data"));

  const navigate = useNavigate();

  const handleVendorBranchStatusUpdate = async (branch) => {
    let status = branch.status == 1 ? 0 : 1;
    const body = {
      master_branch_id: branch.id,
      status: status,
    };
    dispatch(updateVendorBranchStatus(body))
      .unwrap()
      .then(() => {
        fetchData();
      })
      .catch((err) => {});
  };

  const handleDeleteVendorBranch = async (branch) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete this branch?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { master_branch_id: branch.id };
        dispatch(deleteVendorBranch(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => {});
      }
    });
  };

  const columns = [
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-center",
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "dt-left",
      render: (text, record) => (
        <button
          className="text-primary text-decoration-underline border-0 bg-transparent"
          onClick={() => showList(record)}
        >
          {text}
        </button>
      ),
      searchable: true,
      sortable: true,
    },
    {
      dataIndex: "branch_code",
      title: "Code",
      className: "dt-left",
      searchable: true,
      sortable: true,
    },
    {
      dataIndex: "pincode_district",
      title: "City",
      className: "dt-left",
      searchable: true,
      sortable: true,
      render: (text, record) => {
        // Access the nested property
        return record.pincode ? record.pincode.district : "-";
      },
    },
    {
      dataIndex: "pincode_state_name",
      title: "State",
      className: "dt-left",
      searchable: true,
      sortable: true,
      render: (text, record) => {
        // Access the nested property
        return record.pincode ? record.pincode.state_name : "-";
      },
    },
    userData.role == Role.MASTER_VENDOR && {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch
          checked={status == 1}
          onChange={() => handleVendorBranchStatusUpdate(record)}
        />
      ),
      className: "dt-center",
    },
    userData.role == Role.MASTER_VENDOR && {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => showList(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteVendorBranch(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const getRoute = (role, path) => {
    if (role === Role.SUPER_ADMIN) {
      return `/super-admin/branch/${path}`;
    } else if (role === Role.BANKS || role === Role.MASTER_VENDOR) {
      return `/admin/branch/${path}`;
    } else {
      return `/user/branch/${path}`;
    }
  };

  const addBranchManage = () => {
    navigate(getRoute(userData.role, "add"));
  };

  const showList = async (branch) => {
    navigate(getRoute(userData.role, "info"), {
      state: { BranchId: branch.id },
    });
  };

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    <h5>
                      {userData.role == Role.MASTER_VENDOR
                        ? "Branch Management"
                        : "My Branches"}
                    </h5>
                  </div>
                </div>
                {userData.role == Role.MASTER_VENDOR ? (
                  <div className="col-md-4">
                    <div className="button-box">
                      <ul>
                        <li>
                          <button
                            className="blue-btn"
                            onClick={addBranchManage}
                          >
                            {" "}
                            + Add New{" "}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <section id="branch-management">
              <AntdTable
                dataSource={vendorBranches}
                columns={columns}
                pageNo={pageNo}
                pageSize={pageSize}
                setPageNo={setPageNo}
                setPageSize={setPageSize}
                total={total}
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default BranchManagement;
