import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user_data'));
  const isAuthenticated = Boolean(localStorage.getItem('token'));

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (user && roles.includes(user.role)) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default PrivateRoute;
